@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

@import "style/resources.scss";

.BasketContinue-actions {
  margin-right: 0;
  line-height: 2 * 20px;
  display: flex;
  justify-content: flex-end;
}

.BasketContinue-button {
  @extend %continue-button;
}

.BasketContinue-button-buyMore {
  background-color: transparent;
}

.BasketContinue-information-promocode-vale {
  color: $color-silver;
  font-size: $font-size-info;
  line-height: $line-height-normal;
}

.BasketContinue-button:hover {
  @extend .BasketContinue-button;

  background-color: $color-la-palma;
}

.BasketContinue-button[disabled],
.BasketContinue-button[disabled]:hover {
  @include button-disabled;
}

.BasketContinue-button:active {
  @extend .BasketContinue-button;

  background-color: $color-la-palma-dark;
}

@include desktop {
  .BasketContinue-button {
    width: 235px;
  }

  .BasketContinue-buyMore {
    margin-right: 24px;

    font-size: 14px;
    text-align: right;
  }

  .BasketContinue-information-promocode-vale {
    display: flex;

    justify-content: flex-end;

    margin-top: 10px;
    margin-right: 6px;
    text-align: center;
  }
}

@include mobile {
  .BasketContinue {
    padding: 16px 0 1px 0;

    background-color: $color-wild-sand;
  }
  .BasketContinue-actions {
    display: flex;

    flex-flow: column-reverse;

    padding-right: 10px;
    padding-left: 10px;
  }
  .BasketContinue-button {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;

    letter-spacing: 0.05em;
  }

  .BasketContinue-buyMore {
    font-size: 14px;
    text-align: center;
    color: var(--tom-color-interaction-darker);
    font-weight: 700;
  }

  .BasketContinue-button-buyMore {
    margin: var(--tom-spacing-2xsm) 0;
    padding: var(--tom-spacing-xsm);
  }

  .BasketContinue-information-promocode-vale {
    margin-bottom: 16px;

    text-align: center;
  }
}
