@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.ValidateAccountOtp,
.ValidateAccountOtp-modal {
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  border-radius: 15px;
  width: 408px;
  @include desktop {
    padding: 40px;
  }
  @include mobile {
    margin-top: 0;
  }
}

.ValidateAccountOtp-modal {
  @include desktop {
    margin: auto;
    width: auto;
    height: auto;
    padding: 0;
  }
  @include mobile {
    width: auto;
    height: 100vh;
  }
}
