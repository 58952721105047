@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

$label-size: 162px;
$label-space: $label-size + 20px;

.LoginBox-title {
  margin-bottom: 20px;
  margin-left: $label-space;

  font-size: 18px;
  font-weight: $font-weight-bold;
}

.LoginBox-form {
  @include desktop {
    margin-bottom: 20px;
  }
}

.LoginBox-form-continue {
  @extend %continue-button;

  margin-left: $label-space;
}

.LoginBox-help {
  @include mobile {
    margin-bottom: $mobile-spacing;
  }

  margin-left: $label-space;

  color: $color-gray;
  font-size: 14px;
}

.LoginBox-strong {
  color: $color-gray-neutral;
}

.LoginBox-form-error--unique {
  @apply border-thin border-danger-default bg-danger-lighter-inverted rounded-sm p-xsm pr-md text-on-surface-2 font-2xsm-regular flex flex-col mb-sm;
  @apply md:ml-[182px] md:w-[250px];
}

@include mobile {
  .LoginBox .FormGroup,
  .SignupBox .FormGroup {
    margin-bottom: 5px;
  }

  .LoginBox-title {
    margin: 0;

    font-size: 14px;
    font-weight: $font-weight-semi-bold;
  }

  .LoginBox-form-continue {
    width: 100%;
    height: 50px;
    margin: 0;
  }

  .LoginBox-help {
    margin-top: 5px;
    margin-left: 0;
  }

  .LoginBox-redirect {
    margin-top: 5px;
    margin-left: 0;
  }

  .LoginBox-form-error--unique {
    margin-top: 0;
    margin-left: 0;
  }
}

@include desktop {
  .LoginBox,
  .SignupBox {
    display: flex;

    flex-flow: column;
    align-items: flex-start;
  }

  .LoginBox-form .FormGroup-label {
    width: $label-size;
  }
}
