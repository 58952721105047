.mobile-only {
  display: none;
}

@include mobile {
  .mobile-only {
    display: block;
  }

  .mobile-hidden {
    display: none;
  }
}
