@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.InputPinCode-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 380px;
}

.InputPinCode-form {
  display: flex;
  width: 100%;
  max-width: 312px;
  height: 48px;
  align-items: center;
  justify-content: space-between;
}

.InputPinCode-input {
  width: 42px;
  height: 48px;
  border: 1px solid var(--tom-color-on-surface-6);
  border-radius: 12px;
  font-weight: 400;
  color: var(--tom-color-on-surface-5);
  font-size: 1.5rem;
  text-align: center;
  gap: 8px;

  &:focus {
    border: 2px solid var(--tom-color-brand-default);
    outline: none;
    background-color: var(--tom-color-on-interaction-lighter);
  }
}

.InputPinCode-wrapper-error {
  display: flex;
  max-width: 312px;
  color: var(--tom-color-on-danger-default-inverted);
  margin-top: 4px;
}

.InputPinCode-wrapper-error .ti {
  width: 24px;
  height: 24px;
  margin: 5px;
}

.InputPinCode-message-error {
  text-align: left;
}

.InputPinCode-error svg {
  width: 25px;
  height: 25px;
}

.InputPinCode-error {
  border-color: var(--tom-color-on-danger-default-inverted);
  background-color: var(--tom-color-on-danger-lighter);
}

.InputPinCode-success {
  border-color: var(--tom-color-success-lighter);
}
