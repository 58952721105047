@mixin border_bottom() {
  box-shadow: 0 1px 0 0 $color-mercury, 0 2px 0 0 $color-white;
}

@mixin border_top() {
  box-shadow: 0 -1px 0 0 $color-mercury, 0 2px 0 0 $color-white;
}

%bordered_item {
  @include border_bottom();
}

%bordered_item_top {
  @include border_top();
}

%list {
  %list_item {
    @extend %bordered_item;
  }

  %list_item:first-child {
    padding-top: 0;
  }

  %list_item:last-child {
    box-shadow: none;
  }
}
