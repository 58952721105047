@mixin CloseIcon {
  position: absolute;
  top: 20px;
  right: 20px;

  width: 21px;
  height: 21px;

  cursor: pointer;

  fill: $color-lochmara;
}
