@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.OrderReviewPackage {
  padding-bottom: 20px;

  font-size: 14px;

  &-international {
    margin: 2px 0 8px;
  }
}

.OrderReviewPackage-samedayMessage {
  color: $color-california;
}

@include mobile {
  .OrderReviewPackage {
    padding-right: 15px;
    padding-left: 15px;
  }
}
