@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.Promocode-promocode__text {
  display: inline;
}

.Promocode__label {
  cursor: pointer;
  text-decoration: underline;
}

.Promocode__label--disabled {
  text-decoration: none;
}

.Promocode__input-group {
  display: inline;
}

.Promocode__link-button {
  @extend %link-button;

  margin-left: 16px;

  color: $color-gray;
}

@mixin promocode-error {
  margin-top: 10px;
  margin-bottom: 20px * 2;

  color: $color-cinnabar;
}

%promocode-error {
  @include promocode-error();
}

.Promocode__error-message {
  @extend %promocode-error;
  @include desktop {
    white-space: nowrap;
  }
}

.Promocode__no-discount-message {
  @extend %promocode-error;
}

.Promocode {
  display: inline-flex;
}

@include desktop {
  .Promocode {
    align-items: center;

    height: 40px;
  }

  .Promocode__form .buttonWithin {
    position: static;
  }
}

@include mobile {
  .Promocode .FormGroup-inputGroup {
    display: flex;

    flex-flow: wrap;
  }

  .Promocode {
    padding: 20px 10px 0;
  }

  .Promocode__form {
    display: flex;

    flex-flow: column;
    flex-wrap: wrap;
  }

  .Promocode__form .inputWithin {
    width: 50%;
    margin: 0;
  }

  .Promocode-promocode__text {
    display: contents;
  }

  .Promocode__label {
    width: 100%;
    margin-bottom: $mobile-spacing;
    font: var(--tom-font-xsm-bold);
    color: var(--tom-color-interaction-darker);
  }

  .Promocode__link-button {
    margin-left: 10px;
    padding-top: 11px;
  }

  .Promocode__error-message {
    @include promocode-error();

    display: flex;

    margin-bottom: 0;

    text-align: left;
  }

  .Promocode__no-discount-message {
    @include promocode-error();

    margin-bottom: 0;
  }
}
