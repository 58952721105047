%button-within {
  @include rounded-with-border();
  @include desktop {
    height: 40px;

    background-color: $color-wild-sand;

    font-size: 16px;

    position: absolute;
    &:hover {
      background-color: $color-silver-light;
    }

    &:active {
      background-color: $color-silver-chalice;
    }
  }
  @include mobile {
    width: 15%;
    height: 52px;
    margin: 0;
    padding-top: 0;
    line-height: 52px;
  }

  margin-left: 0;
  padding: 11px;

  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  cursor: pointer;
}

@mixin continue-button {
  @include rounded();
  @include desktop {
    width: $button-width;
  }

  border: 0;
  background-color: $color-apple;

  color: $color-white;
  font-size: 18px;
  line-height: $line-height-big * 20px;
  text-align: center;
  text-decoration: none;
}

@mixin DeliveryPage-continue {
  @include continue-button();

  align-self: flex-end;

  width: 224px;
  margin-top: 10px;
  margin-bottom: 20px;
}

%flex-continue-button {
  @include continue-button;

  display: flex;

  justify-content: center;

  width: 224px;
}

%continue-button {
  @include continue-button;

  &:hover {
    background-color: $color-la-palma;
  }

  &:active {
    background-color: $color-la-palma-dark;
  }

  &:disabled {
    @extend %button-disabled;
  }
}

%continue-button--reverse {
  @include continue-button;

  border: 2px solid $color-apple;
  background-color: $color-white;

  color: $color-apple;
  font-size: 16px;

  &:hover {
    border-color: $color-la-palma;
    background-color: $color-white;

    color: $color-la-palma;
  }

  &:active {
    border-color: $color-la-palma-dark;
    background-color: $color-white;

    color: $color-la-palma-dark;
  }

  &:disabled {
    @extend %button-disabled;
  }
}

%next-button,
%secondary-button {
  @extend %continue-button;

  background-color: $color-lochmara;
}

%bobby-button {
  @extend %continue-button;

  border: 1px solid $color-apple;
  background-color: $color-white;

  color: $color-apple;
}

%reset-button {
  border: 0;
  background: none;
}

%link-button {
  @extend %reset-button;

  text-decoration: underline;
}

%button-link {
  @extend %link-button;

  color: $color-gray;
}

@mixin button-disabled {
  border-color: $color-mercury;
  background-color: $color-mercury;

  color: $color-silver;
  cursor: not-allowed;
}

%button-disabled {
  @include button-disabled;
}

%not-allowed {
  cursor: not-allowed;
}

%link {
  color: $color-gray;
}

@include desktop {
  %button-link {
    display: block;
  }
}
