@mixin input-within() {
  @include default-input();

  width: 120px;
  margin-left: 0;
  padding: 0 10px;

  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@mixin default-input {
  @include rounded-with-border();
  @include desktop {
    height: 40px;

    font-size: 16px;
  }
  @include mobile {
    height: 52px;
    padding-top: $mobile-spacing;
    padding-bottom: 0;

    font-size: 14px;

    &::placeholder {
      color: transparent;
    }
  }

  margin: 0 auto;
  padding: 0 6px;
}

@mixin checked--icon {
  width: 15px;
  height: 15px;
  margin-top: 1px;
  margin-left: 1px;

  fill: $color-white;
}

%default-input {
  @include default-input();
}

%default-input-disabled {
  @extend %not-allowed;

  background-color: $color-wild-sand;
}

%input-within {
  @include input-within();
}

%input-numeric {
  padding-top: 2px;
}

%input--withError {
  border-color: $color-cinnabar;

  &:focus {
    outline: 0;
    box-shadow: 0 0 4px 0 rgba($color-cinnabar, 0.8);
  }
}
