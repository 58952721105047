@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.PaymentBox {
  @extend %GenericBox;
  @extend %list;

  margin-right: -20px;
  margin-left: -20px;
  padding-bottom: 0;
}

.PaymentBox-icon {
  width: 50px;
  height: 30px;
  padding-right: 10px;
  padding-left: 10px;

  vertical-align: middle;

  fill: $color-mine-shaft;
}

.PaymentBox-icon--flag {
  @extend .PaymentBox-icon;
}

.PaymentBox-icon--flagVisa {
  @extend .PaymentBox-icon;

  width: 55px;
  height: 30px;
  padding-right: 10px;
  padding-left: 5px;
}

@mixin PaymentBox-line {
  @extend %list_item;

  padding: 10px 0;
}

.PaymentBox-line {
  @include PaymentBox-line;
}

.PaymentBox-line-disabled {
  @extend .PaymentBox-line;

  color: $color-silver;
}

.PaymentBox-line-label {
  display: inline-flex;

  align-items: center;

  width: 100%;

  cursor: pointer;
}

.PaymentBox-line-label .CheckboxGroup {
  margin-left: 0;
  margin-right: 20px;
  padding-left: 0;
}

.PaymentBox-line-label .CheckboxGroup-labelText {
  margin-left: 20px;
}

.PaymentBox-line-info-label--success {
  color: $color-apple;
}

.PaymentBox__special-message {
  @include rounded();

  margin-left: 15px;
  padding: 3px 7px;

  background-color: $color-apple;

  color: $color-white;
  font-size: 13px;
  letter-spacing: 1px;
}

.ImgCardLuizaGold {
  position: relative;
  top: 5px;

  margin-right: 10px;
  margin-left: 5px;

  fill: $color-mine-shaft;
}

.ImgCardLuizaSilver {
  @extend .ImgCardLuizaGold;
}

@include desktop {
  .PaymentBox-line-info-label {
    flex: 0 0 590px;

    margin-left: auto;
  }

  .PaymentBox-line-label .CheckboxGroup {
    margin-bottom: 18px;
  }
}

@include mobile {
  .PaymentBox {
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }

  .PaymentBox-line-info-label {
    display: block;

    margin-top: 5px;
    margin-left: 30px;
  }

  .PaymentBox-line-label {
    display: block;

    padding-bottom: $mobile-spacing;
  }

  .PaymentBox-line-label .CheckboxGroup {
    display: inline-block;
    vertical-align: middle;
  }

  .SecurePurchase {
    @include PaymentBox-line();

    text-align: center;
  }

  .SecurePurchase-description {
    margin-left: 4px;

    font-size: 14px;
  }
}
