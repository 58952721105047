@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.InputRadioButton,
.radio-button-off,
.radio-button-on {
  display: none;
}

.InputRadioButton--on,
.InputRadioButton--off {
  display: inline-block;

  vertical-align: middle;
}

.InputRadioButton--on {
  .radio-button-on {
    display: block;
  }
}

.InputRadioButton--off {
  .radio-button-off {
    display: block;
  }
}
