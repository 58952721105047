@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

@include desktop {
  .App {
    width: $desktop-width;
    min-height: calc(100vh - #{$footer-height} - #{$header-height});
    margin-right: auto;
    margin-left: auto;
    padding: 0 20px;
  }

  .CustomerArea-theme {
    background-color: var(--tom-color-on-surface-9);
    width: auto;
    margin-bottom: -18px;
  }
}

@include mobile {
  .App {
    overflow: hidden;
    width: auto;
  }

  .CustomerArea-theme {
    margin-bottom: -18px;
    height: 100vh;
  }
}
