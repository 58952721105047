@import "../../../../../style/variables/colors.scss";
@import "../../../../../style/variables/typography.scss";
@import "../../../../../style/variables/forms.scss";
@import "../../../../../style/variables/general.scss";
@import "../../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../../style/utils/helpers";
@import "../../../../../style/utils/responsive";
@import "../../../../../style/utils/typography";
@import "../../../../../style/utils/list";
@import "../../../../../style/utils/buttons";
@import "../../../../../style/utils/inputs";
@import "../../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../../style/components/placeholders/GenericBox";

.BasketItemProduct-shipping {
  display: flex;
  align-items: center;
  margin-bottom: 56px;
  @include mobile {
    margin-bottom: 0;
  }
}

.BasketItemProduct-shipping span {
  font-weight: $font-weight-bold;
  @include mobile {
    margin-left: auto;
  }
}

.BasketItemDelivery-price {
  margin-left: 7px;
  color: $color-dark-gray;
}

.BasketItemDelivery-price--free {
  color: $color-apple;
}
