%page-title {
  padding-top: 20px;

  color: $color-lochmara;
  font-size: 24px;
  font-weight: $font-weight-bold;
  line-height: 48px;
}

%modal-title {
  @extend %page-title;

  padding-top: 0;
}

@include mobile {
  %page-title {
    margin-bottom: $mobile-spacing;
    margin-left: $mobile-spacing;
    padding: 0;

    font-size: 18px;
    line-height: $line-height-biggest;
  }
}

%text-contrast {
  color: $color-dark-gray;
  font-weight: $font-weight-bold;
}

%text-info {
  color: $color-dark-gray;
  font-size: $font-size-link;
}
