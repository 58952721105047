@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

@import "components/Basket/Basket.scss";

.BasketTable-header {
  display: flex;
  align-items: center;
  font-weight: $font-weight-bold;
  background-color: $color-on-surface;
  height: 56px;
  padding: 0 10px;
}

.BasketPage-title {
  @extend %page-title;
  padding-top: 0;
  @include mobile {
    margin-bottom: 0;
    margin-left: 16px;
  }
}

.BasketTable-header-quantity {
  width: $basket-quantity-width;
  margin-left: $basket-product-width;

  text-align: center;
}

.BasketTable-header-price {
  width: $basket-price-width;
}

@include mobile {
  .BasketTable {
    padding-top: 0;
  }

  .BasketTable-header {
    height: 16px;
  }

  .BasketTable-items {
    margin-top: 24px;
  }
}
