@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.SignupFormPF {
  @include clearfix();
}

.SignupFormPF-redirect-me {
  align-items: center;
  margin-top: -15px;

  font-size: $font-size-info;
  @include desktop {
    padding-top: -10px;
    margin-bottom: 10px;
    margin-left: $form-left-space;
    padding-left: 20px;
  }
  @include mobile {
    margin-right: 10px;
    margin-bottom: 20px;
  }
}

.SignupFormPF-strong {
  color: $color-gray-neutral;
  font-weight: $font-weight-semi-bold;
}

.SignupFormPF-link {
  color: $color-gray-neutral;
  background-color: transparent;
  text-decoration: underline;
}

.SignupFormPF-divider-line {
  height: 1px;

  border: 0;
  margin-top: -15px;
  margin-bottom: 25px;

  background-color: $color-silver-light;
  @include desktop {
    margin-left: 366px;
  }
}
