@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.Policies-wrapper {
  display: flex;
  flex: 1;
  width: 100%;
  text-align: left;
  flex-direction: column;
  font-size: 1.2rem;
}

.Policies-description {
  position: relative;
  background-color: #fbebdb;
  padding: 14px;
  border-radius: $border-radius-medium;
  display: flex;
  flex-direction: column;

  :first-child {
    font-weight: bold;
    color: $color-cinnabar;
  }

  ::before {
    content: '';
    width: 5px;
    background-color: $color-cinnabar;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.Policies-info-description {
  font-weight: $font-weight-bold;
  color: $color-cinnabar;
}

.Policies-info {
  margin-top: 18px;
}

.Policies-rules-content {
  display: flex;
  margin-top: 8px;
  flex-direction: column;
}
