@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

@import '../../../../style/resources.scss';

.CheckoutHeader {
  display: flex;

  flex-flow: column;

  background-color: $color-azure-radiance;
}

.header-pink .CheckoutHeader {
  background-color: $color-hot-pink;
}

.payday .CheckoutHeader {
  background-color: $color-payday;
}

.black-friday .CheckoutHeader {
  background-color: $color-black;
}

.fantastic-sale .CheckoutHeader {
  background-color: $color-fantastic-sale;
}

.CheckoutHeader-colorsStrip {
  overflow-y: hidden;

  height: $colorsStrip-height;
}

.CheckoutHeader-colorsStrip-image {
  display: block;

  width: 100%;
}

.CheckoutHeader-content {
  width: 100%;
  height: $header-height-mobile - $colorsStrip-height;
}

.CheckoutHeader-logo {
  margin-top: 17px;
  margin-left: 10px;
}

@include mobile {
  .CheckoutHeader-logo-icon {
    width: 90px;
  }
}

@include desktop {
  $header-width: $desktop-width - 40px;

  .CheckoutHeader-content {
    display: flex;

    justify-content: space-between;

    width: $header-width;
    height: $header-height - $colorsStrip-height;
    margin: 0 auto;
  }

  .CheckoutHeader-logo {
    margin-top: 27px;
    margin-left: 20px;
  }

  .CheckoutHeader-colorsStrip {
    width: $header-width;
    margin: 0 auto;
  }
}
