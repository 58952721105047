.input--withError {
  @extend %input--withError;
}

@include mobile {
  input:-webkit-autofill + label,
  .active--input {
    top: 0;
    left: 0;

    color: $color-gray;
    font-size: 80%;
    transition: all 0.125s ease;
  }
}
