@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.SignupOptInMagaluPay {
  margin-bottom: 20px;
  @include desktop {
    margin-top: -40px;
  }
}

.SignupOptInMagaluPay-title {
  b {
    margin-right: 5px;
  }
  @include desktop {
    font-size: 16px;
  }
}

.SignupOptInMagaluPay-description {
  display: block;
  @include desktop {
    margin-left: 366px;
  }
}

.SignupOptInMagaluPay-icon {
  margin-right: 6px;
}

.SignupOptInMagaluPay-terms-link {
  text-decoration: none;
}

.SignupOptInMagaluPay-info,
.SignupOptInMagaluPay-terms-link {
  color: $color-azure-radiance;
  font-weight: $font-weight-semi-bold;
  cursor: pointer;
}

.SignupOptInMagaluPay-terms,
.SignupOptInMagaluPay-info,
.SignupOptInMagaluPay-title {
  display: inline-flex;
  align-items: center;
  @include desktop {
    width: 463px;
    margin-bottom: 10px;
    margin-left: $form-left-space;
    padding-left: 20px;
  }
  @include mobile {
    flex-wrap: wrap;
    margin-right: 10px;
    margin-bottom: 20px;
  }
}

.SignupOptInMagaluPay-info,
.SignupOptInMagaluPay-title {
  @include desktop {
    margin-top: 24px;
  }
}
