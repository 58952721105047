@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.ReceiptPage {
  float: left;

  width: 100%;
  margin-right: 20px;

  -webkit-font-smoothing: antialiased;
}

.ReceiptHeader {
  float: left;

  width: 100%;
  margin-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;

  line-height: 58px;
}

.ReceiptHeader-logo {
  float: left;

  width: 224px;
  margin-right: 20px;
}

.ReceiptHeader-timestamp {
  float: right;

  width: 346px;
  margin-right: 0;
  margin-left: 366px;

  font-size: 14px;
  text-align: right;
}

.ReceiptTitle {
  float: left;

  width: 100%;
  margin-right: 20px;
}

.ReceiptTitle-title {
  float: left;

  width: 100%;
  margin-right: 20px;

  font-weight: $font-weight-bold;
  text-align: center;
}

.ReceiptTitle-subtitle {
  float: left;

  width: 100%;
  margin-right: 20px;

  text-align: center;
}

.ReceiptContent {
  float: left;

  width: 100%;
  margin-right: 20px;
  padding-top: 20px;
}

.ReceiptContent-titleContainer {
  float: left;

  box-sizing: content-box;
  width: 100%;
  margin-right: -20px;
  margin-left: -20px;
  padding-right: 20px;
  padding-left: 20px;

  border-bottom: 1px solid $color-mercury;
}

.ReceiptContent-title {
  float: left;

  width: 100%;
  margin-right: 20px;

  font-weight: $font-weight-bold;
}

.ReceiptContent-details {
  float: left;

  width: 100%;
  margin-right: 20px;
  padding-top: 10px;
}

.ReceiptBasketTable {
  float: left;

  box-sizing: content-box;
  width: 100%;
  margin-right: -20px;
  margin-left: -20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;

  border-bottom: 1px solid $color-mercury;
}

.ReceiptBasketTable-items {
  float: left;

  box-sizing: content-box;
  width: 100%;
  margin-right: -20px;
  margin-left: -20px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-left: 20px;

  border-bottom: 1px solid $color-mercury;
}

.ReceiptBasketTable-title {
  float: left;

  box-sizing: content-box;
  width: 100%;
  margin-right: -20px;
  margin-left: -20px;
  padding-right: 20px;
  padding-left: 20px;

  border-bottom: 1px solid $color-mercury;

  font-weight: $font-weight-bold;
}

.ReceiptBasketTable-total {
  float: left;

  width: 100%;
  margin-right: 20px;
  padding-top: 10px;

  font-weight: $font-weight-bold;
}

.ReceiptBasketTable-title-product {
  float: left;

  width: 712px;
  margin-right: 20px;
}

.ReceiptBasketTable-total-title {
  float: left;

  width: 163px;
  margin-right: 20px;
  margin-left: 549px;

  font-weight: $font-weight-bold;
  text-align: right;
}

.ReceiptBasketTable-title-price,
.ReceiptBasketTable-total-price {
  float: right;

  width: 224px;
  margin-right: 0;
}

/*
* ReceiptBasketPackage
*/
.ReceiptBasketPackage {
  float: left;

  width: 100%;
  margin-right: 20px;
}

.ReceiptBasketPackage-description {
  float: left;

  width: 100%;
  margin-right: 20px;
  padding-top: 20px;
}

.ReceiptBasketPackage-items {
  float: left;

  width: 100%;
  margin-right: 20px;
}

.ReceiptBasketPackage-shippingType {
  float: left;

  width: 100%;
  margin-right: 20px;
}

.ReceiptBasketPackage-shippingType-description {
  float: left;

  width: 712px;
  margin-right: 20px;
}

.ReceiptBasketPackage-shippingType-price {
  float: right;

  width: 224px;
  margin-right: 0;

  font-weight: $font-weight-bold;
}

/*
* ReceiptBasketItem
*/
.ReceiptBasketItem {
  float: left;

  width: 100%;
  margin-right: 20px;
  margin-bottom: 20px;
}

.ReceiptBasketItem-description {
  float: left;

  width: 712px;
  margin-right: 20px;
}

.ReceiptBasketItem-price {
  float: right;

  width: 224px;
  margin-right: 0;
}

/*
* ReceiptBasketAddress
*/
.ReceiptBasketAddress {
  float: left;

  width: 100%;
  margin-right: 20px;
  padding-top: 10px;
}

.ReceiptBasketAddress-description {
  float: left;

  width: 100%;
  margin-right: 20px;
}

.ReceiptBasketAddress-receiver {
  float: left;

  width: 100%;
  margin-right: 20px;
}
