@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.InternationalTaxContingencyModal {
  &-content {
    display: flex;
    gap: 13px;
    margin-bottom: 16px;
    text-align: left;

    &-column {
      display: flex;
      flex-direction: column;
      gap: 13px;
      text-align: left;
    }
  }

  &-icon {
    width: 66px;
    height: 67px;
  }

  .font-sm-regular {
    width: calc(100% - 66px);
    font: var(--tom-font-sm-regular);
    line-height: 125%;
    color: $color-very-dark-gray;
  }

  .font-sm-medium {
    font: var(--tom-font-sm-medium);
    color: $color-very-dark-gray;
  }

  &-btn {
    padding: 11px 100px;
    margin: 0 auto;
    display: block;
    width: fit-content;
  }
}

@include mobile {
  .InternationalTaxContingencyModal {
    &-content {
      flex-direction: column;
      align-items: center;
    }

    .font-sm-regular {
      width: 100%;
    }
  }
}
