@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

@import "components/Basket/Basket.scss";

.BasketAddress {
  display: flex;
}

.BasketAddress-address-change {
  @extend %button-link;
}

.BasketAddress-alterZipcode {
  display: flex;
  align-items: center;
  margin-bottom: 9.04px;
  & > :first-child {
    margin-right: 8px;
    font-size: 16px;
    font-weight: $font-weight-normal;
    line-height: 18.97px;
  }
}

.BasketAddress-address-change__icon {
  display: none !important;
}

.BasketAddress-shipmentCost {
  color: $color-lochmara;
}

.BasketAddress-labelContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
}

.BasketAddress-firstLabel {
  font-size: 17px;
  font-weight: $font-weight-normal;
  margin-bottom: 8px;
  line-height: 19.55px;
}

.BasketAddress-secondLabel {
  font-size: 17px;
  font-weight: $font-weight-bold;
  line-height: 19.55px;
}

.BasketAddress-free {
  color: $color-apple;
  font-weight: $font-weight-bold;
}

.BasketAddress-address {
  width: 100%;
}

@include desktop {
  .BasketAddress {
    line-height: $line-height-biggest;
  }

  .BasketAddress-address-firstLine {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    font-size: 17px;
    line-height: 19.92px;
  }

  .BasketAddress-address-secondLine {
    font-size: 16.7px;
    font-weight: $font-weight-normal;
    line-height: 25.5px;
  }
}

@include mobile {
  .BasketAddress {
    justify-content: space-between;

    padding-bottom: -1px;

    border-bottom: 2px solid $color-mercury;
    margin-top: 16px;
  }

  .BasketAddress-address {
    font-size: 12px;
    border-top: 16px solid $color-on-surface;
    margin-top: -24px;
  }

  .BasketAddress-free {
    align-self: flex-end;
  }

  .BasketAddress-address-firstLine {
    font-size: 16px;
    font-weight: $font-weight-normal;
    line-height: $line-height-normal;
    display: flex;
    flex-direction: column;
    margin: 24px 16px 24px 16px;
  }

  .BasketAddress-address-secondLine {
    line-height: $line-height-normal;
    font-size: 12px;
    font-weight: $font-weight-normal;
  }

  .BasketAddress-alterZipcode {
    justify-content: space-between;
  }

  .BasketAddress-zipcode::before {
    font-size: 16px;
    font-weight: $font-weight-normal;
  }

  .BasketAddress-address-change {
    text-decoration: none;
  }

  .BasketAddress-address-change__label {
    color: var(--tom-color-interaction-darker);
    font: var(--tom-font-xsm-bold);
  }

  .BasketAddress-shipmentCost {
    display: flex;

    justify-content: flex-end;

    margin-right: 20px;

    font-size: 15px;
    font-weight: $font-weight-normal;
  }

  .BasketAddress-firstLabel {
    font-size: 16px;
    font-weight: $font-weight-normal;
    margin-bottom: 8px;
    color: $color-dark-gray;
  }

  .BasketAddress-secondLabel {
    font-size: 16px;
    color: $color-dark-gray;
  }
}
