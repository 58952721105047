@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.AddressList-subtitle {
  @apply font-xsm-bold text-on-surface-4 my-xsm;
}

.AddressList-buttonsContainer {
  @apply flex flex-col justify-end w-full gap-x-md gap-y-sm;
  @apply md:flex-row;
}

.AddressList-newAddressButton {
  @apply btn btn-outline justify-center;
}

.AddressList-confirmButton {
  @apply btn btn-success justify-center transition-colors;
}
