@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.SuccessNotificationModal-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  span {
    max-width: 449px;
    &:first-of-type {
      margin-top: 12px;
      font-size: 1.5rem;
      font-weight: $font-weight-bold;
    }
  }

  button {
    padding: 18px;
    margin-top: 12px;
    color: $color-white;
    font-weight: $font-weight-bold;
    background-color: $color-azure-radiance;
    border-radius: $border-radius-medium;
    width: 100%;
    max-width: 328px;
    border: none;
    @include desktop {
      max-width: 275px;
    }
  }
}

@include mobile {
  .ReactModal__Content {
    width: auto !important;
    margin-top: $header-height-mobile !important;
    padding-top: 14px !important;
    padding-right: $mobile-spacing !important;
    padding-left: $mobile-spacing !important;

    text-align: left !important;
  }

  .Modal-content {
    margin-top: 30px;

    text-align: center;
  }

  .Forgot-title {
    font-size: 18px;
    text-align: center;
  }

  .Forgot-description {
    margin-right: $mobile-spacing;
    margin-bottom: $mobile-spacing;
    margin-left: $mobile-spacing;

    line-height: $line-height-small;
    text-align: center;
  }

  .Forgot-form .continueButton {
    width: 100%;
    height: 50px;
    margin: 0;
  }
}
