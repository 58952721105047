@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.InternationalTaxContingency {
  &-text {
    display: block;
    margin-top: 12px;
    font: var(--tom-font-2xsm-regular);
    text-align: right;
    line-height: 16px;
  }

  &-link {
    color: var(--tom-color-on-surface-5);
    text-decoration-line: underline;
    cursor: pointer;
  }
}
