@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.BobbyCompactProduct {
  float: left;

  width: 468px;
  margin-right: 20px;
}

.BobbyCompactProduct-title {
  float: left;

  width: 346px;
  margin-right: 20px;

  cursor: pointer;
}

.BobbyCompactProduct-price {
  float: left;

  width: 346px;
  margin-right: 20px;
  margin-bottom: 10px;

  cursor: pointer;
}

.BobbyCompactProduct-productRow {
  float: left;

  width: 100%;
  margin-right: 20px;

  &:hover {
    .BobbyCompactProduct-title,
    .BobbyCompactProduct-price {
      text-decoration: underline;
    }
  }
}

.BobbyCompactProduct-productRow-left {
  float: left;

  width: 102px;
  margin-right: 20px;

  cursor: pointer;
}

.BobbyCompactProduct-productRow-right {
  float: right;

  width: 346px;
  margin-right: 0;
}

.BobbyCompactProduct-title-link {
  color: $color-mine-shaft;
  text-decoration: none;
}

.BobbyCompactProduct-price-link {
  color: $color-dark-gray;
  font-size: $font-size-marker-info;
  font-weight: $font-weight-semi-bold;
  text-decoration: none;
}

.BobbyCompactProduct-buttonRow {
  float: left;

  width: 100%;
  margin-right: 20px;
}

.BobbyCompactProduct-button {
  @extend %bobby-button;

  float: left;

  width: 102px;
  margin-right: 20px;
  margin-left: 122px;

  font-size: 14px;
  line-height: 30px;
}

.BobbyCompactProduct-button:hover {
  @extend .BobbyCompactProduct-button;

  border: 1px solid $color-la-palma;

  color: $color-la-palma;
}

.BobbyCompactProduct-button:active {
  @extend .BobbyCompactProduct-button;

  border: 1px solid $color-la-palma-dark;

  color: $color-la-palma-dark;
}

.BobbyCompactProduct-button-icon {
  width: 16px;
  height: 16px;

  vertical-align: sub;

  fill: $color-apple;
}

.BobbyCompactProduct-button-icon:hover {
  @extend .BobbyCompactProduct-button-icon;

  fill: $color-la-palma;
}

.BobbyCompactProduct-button-icon:active {
  @extend .BobbyCompactProduct-button-icon;

  fill: $color-la-palma-dark;
}

@include mobile {
  .BobbyCompactProduct-title-link,
  .BobbyCompactProduct-price-link {
    width: 100%;
    padding: 2% 0;

    font-size: 14px;
    text-align: left;
  }

  .BobbyCompactProduct {
    width: 100vw;
    margin-right: 0;

    img {
      width: 25vw;
    }
  }

  .BobbyCompactProduct-productRow {
    width: 100%;
    margin: 0;
  }

  .BobbyCompactProduct-productRow-left {
    width: 30%;
    margin: 0;
  }
  .BobbyCompactProduct-productRow-right {
    width: 70%;
    margin: 0;
  }
  .BobbyCompactProduct-title {
    width: auto;
  }
  .BobbyCompactProduct-price {
    width: auto;
  }
  .BobbyCompactProduct-button {
    margin-left: 30%;
  }
}
