@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

@import "components/Basket/Basket.scss";

.BasketTable-header-package {
  display: flex;
  align-items: center;

  &-title {
    display: flex;
    font-weight: bold;
    align-items: flex-end;
  }
  @include desktop {
    background-color: $color-on-surface;
    height: 56px;

    &-title {
      size: 20px;
      padding-left: 12px;
    }

    &-logo {
      margin: 0 8px;
    }
  }
  @include mobile {
    &-title {
      size: 16px;
      padding: 24px 0 0 16px;
      flex-wrap: wrap;
      border-top: 16px solid $color-on-surface;
      width: 100%;
    }
    &-logo {
      margin: 0 6px;
    }
  }
}

.BasketTable-items-package {
  @include mobile {
    margin-top: 24px;
  }
}

.Basket-shipment-box {
  border-top: 1px solid $color-surface;
  @include desktop {
    padding-top: 32px;
  }
  @include mobile {
    padding: 16px;
  }
}

.Basket-shipment-title {
  font-weight: $font-weight-bold;
  color: $color-dark-gray;
}
