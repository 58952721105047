@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

@import "style/resources.scss";

.AddressForm {
  @include clearfix();
}

@include desktop {
  .AddressForm {
    display: flex;
  }

  .AddressForm-form-label-cancel {
    margin-right: 20px;
    padding-left: 20px;
  }

  .AddressForm-form-label--large {
    display: inline-flex;

    justify-content: flex-end;

    width: 300px;
    margin-right: 20px;
    margin-left: 179px;
    padding-top: 10px;
  }
}

.AddressForm-form-label-cancel {
  display: inline-flex;

  justify-content: flex-end;
}

.AddressForm-form-zipcode-link {
  @include mobile {
    margin-top: 10px;
  }

  display: inline-flex;
}

.AddressForm-cancel {
  width: 590px;
  margin-right: 0;
  margin-left: $form-left-space + $desktop-spacing;
}

@include desktop {
  .AddressForm-form-group-cancel {
    margin-left: 263px;
  }
}

@include mobile {
  $input-width: 85%;

  .AddressForm [name="zipcode"]::placeholder {
    color: $color-gray;
  }

  .AddressForm-form-label--large {
    position: initial;

    justify-content: flex-start;

    width: 100%;
    padding-top: 8px;

    color: $color-gray;
    &.active--input {
      padding-top: 12px;
    }
  }

  .AddressForm-form-group-cancel {
    display: flex;

    align-items: center;
  }

  .AddressForm .FormGroup-inputGroup {
    text-align: left;
  }

  .AddressForm-form-zipcode-link--href {
    display: flex;
  }
}
