@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.bottom-sheet-header {
  border-bottom: none !important;
}

.bottom-sheet-content {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.bottom-sheet-footer {
  border-top: none !important;
}
