@import "../../../../../style/variables/colors.scss";
@import "../../../../../style/variables/typography.scss";
@import "../../../../../style/variables/forms.scss";
@import "../../../../../style/variables/general.scss";
@import "../../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../../style/utils/helpers";
@import "../../../../../style/utils/responsive";
@import "../../../../../style/utils/typography";
@import "../../../../../style/utils/list";
@import "../../../../../style/utils/buttons";
@import "../../../../../style/utils/inputs";
@import "../../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../../style/components/placeholders/GenericBox";

.WhatsAppFeedBack__icon-whatsapp-registered {
  height: 46px;
  margin-top: 12px;
  margin-right: 10px;
  margin-left: 10px;
}

.WhatsAppFeedBack__content {
  margin-top: 20px;
}

.WhatsAppFeedBack__description {
  margin-bottom: 15px;

  font-size: 16px;
  line-height: 18px;
  letter-spacing: normal;
}

@include mobile {
  .WhatsAppFeedBack__icon-whatsapp-registered {
    margin-right: 10px;
    margin-left: 10px;
  }

  .WhatsAppFeedBack__content {
    width: 75%;
  }
}
