@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.EmptyBasket-page-title {
  @extend %page-title;
}

.EmptyBasket-title {
  font-weight: $font-weight-bold;
}

.EmptyBasket-box {
  margin-top: 20px;
  padding: 20px;

  background-color: $color-wild-sand;
}

@include desktop {
  .EmptyBasket {
    width: 100%;
  }

  .EmptyBasket-box {
    @include rounded();

    float: left;

    box-sizing: content-box;
    width: 100%;
    margin-right: -20px;
    margin-left: -20px;
  }

  .EmptyBasket-title {
    padding-top: 30px;
  }

  .EmptyBasket-asset {
    float: left;
  }
}

@include mobile {
  .EmptyBasket-box {
    text-align: center;
  }

  .EmptyBasket-page-title {
    margin-top: $mobile-spacing;
    margin-bottom: 0;
    margin-left: 20px;
  }
}
