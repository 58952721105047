@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.Navigation {
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--tom-spacing-lg);
    margin-bottom: var(--tom-spacing-lg);
    @include mobile {
      margin-top: var(--tom-spacing-md);
      margin-bottom: var(--tom-spacing-md);
    }
  }
}
