@import "../../../../../style/variables/colors.scss";
@import "../../../../../style/variables/typography.scss";
@import "../../../../../style/variables/forms.scss";
@import "../../../../../style/variables/general.scss";
@import "../../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../../style/utils/helpers";
@import "../../../../../style/utils/responsive";
@import "../../../../../style/utils/typography";
@import "../../../../../style/utils/list";
@import "../../../../../style/utils/buttons";
@import "../../../../../style/utils/inputs";
@import "../../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../../style/components/placeholders/GenericBox";

.WhatsAppOption__container-modules {
  position: relative;

  height: 120px;
}

.WhatsAppOption__lateral-borders {
  display: flex;

  align-items: center;
}

.WhatsAppOption__icon-whatsapp--magalu {
  margin-right: 10px;
}

.WhatsAppOption__description--magalu {
  margin-bottom: 15px;

  font-size: 16px;
  line-height: 18px;
  letter-spacing: normal;
}

.WhatsAppOption__option-in--magalu {
  padding: 6px;

  border: solid 1px $color-white;
  border-radius: 4px;

  font-size: 16px;
  cursor: pointer;
  letter-spacing: normal;
}

%WhatsAppOption_section {
  position: absolute;
  top: 0;

  display: flex;

  align-items: center;

  width: 100%;
  height: 120px;
  padding: 10px;

  border-radius: 4px;
  background-color: $color-lochmara;

  color: $color-white;
  transition: all 0.3s linear;
}
.WhatsAppOption__block {
  @extend %WhatsAppOption_section;

  z-index: 1;

  opacity: 1;
}
.WhatsAppOption__none {
  @extend %WhatsAppOption_section;

  z-index: 0;

  opacity: 0;
}

@include mobile {
  .WhatsAppOption__icon-whatsapp--magalu {
    width: 25%;
  }

  .WhatsAppOption__content--magalu {
    width: 75%;
  }
}
