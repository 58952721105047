@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.OrderReviewAddress {
  margin-bottom: 20px;

  font-size: 14px;
}

@include mobile {
  .OrderReviewAddress {
    padding-right: 15px;
    padding-left: 15px;

    border-bottom: 15px solid $color-mercury;
  }
  .OrderReviewAddress-description {
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
  }

  .OrderReviewAddress-description-edit {
    margin-top: var(--tom-spacing-xsm);
    align-self: flex-end;
  }
}

.OrderReviewWarning {
  border-radius: 8px;
  box-sizing: border-box;
  padding: 8px 12px 8px 12px;
  background-color: $color-california-light;
  border: 1px solid $color-california-border;
  width: 80%;
  margin-bottom: 12px;
}

@include mobile {
  .OrderReviewWarning {
    width: 100%;
  }
}

.OrderReviewWarning svg {
  float: left;
  margin-right: 10px;
  margin-top: -4px;
}

.OrderReviewWarning .warning-icon-amber {
  fill: #f9c52e;
}

.OrderReviewWarning .TextWarning {
  width: 100%;
}
