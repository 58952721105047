@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

@import "../../../../style/resources.scss";

.CheckoutFooter {
  margin-top: 20px;
  padding: 0 12px;

  background-color: $color-wild-sand;

  color: $color-gray;
  font-size: 13px;
}

.CheckoutFooter-content {
  width: 100%;
}

.payday .CheckoutFooter {
  background-color: $color-payday;

  color: $color-white;
}

.black-friday .CheckoutFooter {
  background-color: $color-black;

  color: $color-white;
}

.fantastic-sale .CheckoutFooter {
  background-color: $color-fantastic-sale;

  color: $color-fantastic-sale-text-footer;
}

.CheckoutFooter-icon-description {
  color: $color-gray;
}

.payday .CheckoutFooter-icon-description {
  color: $color-white;
}

.black-friday .CheckoutFooter-icon-description {
  color: $color-white;
}

.fantastic-sale .CheckoutFooter-icon-description {
  color: $color-fantastic-sale-text-footer;
}

.payday .CheckoutFooter-top-item--logo-ebit .ImgEbitDiamante {
  display: inline-block;

  width: 62px;
  height: 62px;

  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABfCAMAAABfuyCDAAABCFBMVEUAAAD4+Pn4+Pn4+PmX0eGn3evb29yEhISq3er4+PmCx9v4+PnH6O+CyNy3t7f4+PnF6O/4+PmCx9vH6O+Cx9uCx9v4+PmCx9v4+Pn4+PnqISbqISbqISbqISb4+PmhoaH4+PnqISbj4+TqISb4+Pl1dXbqISZcXFzqISb4+PmCx9uCx9vqISaCx9vqISbx8fLqISai2OaCx9v4+PnT09OCx9vqISa95e6Cx9uCx9tGRka/5e6Y0eHBwcHqISai2+qCx9v4+PnH6O/qISb///+W1OWOz+Ke2emr3uzA5u6z4u2Hy9665O3uS0/+8vL1kJP6xMX2nqHzfoHxYmXsMzj85OX71tf3rK6cjUOoAAAAP3RSTlMAQIDA0ODAQPbgECDx8IBgYBDPMDBg8J8w0IAQ4NCgYFBA0KBwMCAfu5CQe29QMPDw5t+wsK9fU0AgEMvEkJDGTfVLAAADkElEQVRo3uzSy4qDMBTG8bMyOx/ARIqKl4V0uiizKMyAfGfrpWMv7/8mk2NswW2yK/4hhGx+mkPoE8sKg7TIyKa6riNXZYwOhHUDV6M3dAbI17ou9pZT4DaOYw+kG5ralsLoAriydAUSR9eqJlcQXQEz8zANzDNOWuhIVklkd1ly8qJb4MHco2eegG9Lu74uC/2T+9MJwGsDkCz0Wf41FjpoIL9Cr630gUTNg+kEuMtA8HeDG4h4B9lC6aNFmYfngwfrV4H0tub1+O49CnK0qFE4XZ2AeZyediBGL3QcRzLxNx1dlO9IDFzpkYR2nWmlczn52joxAidkq3P3qmN60aW7gn8ZvVNKlSSVqqa9vb1/UskmBWEYCKMjKEioELSld1BcKIiCguUD7Y+5/3lciM4MaaWZvl2Y5qV9TQ9+IbjKxZmmscnuTIsgVtmGpjDLwK4AoPutGkxyewDN19UBejnF7SHeswGUO4DdNjOCNqP9hbe7Pas+Ju0G2G0zA9LM39GA3QYzxw1gPvk7WN1eitjDpwUY3R5My7+Q3bJQdjaagQ4RrT7N01jcXHFYxhzUEzdHYykqyfMe86okOY1np3bWkblW84JSKOXWR6R+yvGWknDrinn97VFSIu74p8hDzNaOUslXoshwj6OjdC7DRXiyyslCERWJe+zJRslFBnoUZOXUX6T/clivYN3X491OGewmCERR9M6b1YSYCSYS2BAXbtS4Mk3qyrwFuOj//0/fAzuiUGo7uConRnJRb/B68A0RnBYDN3s9qN2fFfzo3+SLE+JY9Rapgnax7B8XqSPl6Cv4sMceU7C8U7vqaxerYN3901uiQ7wmYY+g3STdt0WqvhzxCtZhj3dMye6q9mVIu3gFq3aPHaZFNal1D9Vu8u6FLHIZliNewepcL7R5eg71WbR7Dav6gJl/jWHFIuMcR94gLTN2BG4AsYEQktUPUBstK2as2hKRx5oLeQCFZMde6oWv6pDYw7An0hOWSUjGqgkNtrmENZdAYj1bKNfqkJyDYdOesPzUICR9GW9DF1ixGu+SZ9+pVvDTIAbImY+daqczabxLcK5TTQKeGKRgx2v49gvQN4PAc/mLQUojYMPWsEvTLNusCyYujJASb+SQhAQ4vlUbYfRnbEiyLMFW1s6dpBLcYIgVCgnwnWplZJHUNKQmB5on5Hol17OJUZKQAMgR0Dfm7WuYmZmZmXkRn1OFmFtAC0JeAAAAAElFTkSuQmCC");
  background-size: contain;
}

.black-friday .CheckoutFooter-top-item--logo-ebit .ImgEbitDiamante {
  display: inline-block;

  width: 62px;
  height: 62px;

  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABfCAMAAABfuyCDAAABCFBMVEUAAAD4+Pn4+Pn4+PmX0eGn3evb29yEhISq3er4+PmCx9v4+PnH6O+CyNy3t7f4+PnF6O/4+PmCx9vH6O+Cx9uCx9v4+PmCx9v4+Pn4+PnqISbqISbqISbqISb4+PmhoaH4+PnqISbj4+TqISb4+Pl1dXbqISZcXFzqISb4+PmCx9uCx9vqISaCx9vqISbx8fLqISai2OaCx9v4+PnT09OCx9vqISa95e6Cx9uCx9tGRka/5e6Y0eHBwcHqISai2+qCx9v4+PnH6O/qISb///+W1OWOz+Ke2emr3uzA5u6z4u2Hy9665O3uS0/+8vL1kJP6xMX2nqHzfoHxYmXsMzj85OX71tf3rK6cjUOoAAAAP3RSTlMAQIDA0ODAQPbgECDx8IBgYBDPMDBg8J8w0IAQ4NCgYFBA0KBwMCAfu5CQe29QMPDw5t+wsK9fU0AgEMvEkJDGTfVLAAADkElEQVRo3uzSy4qDMBTG8bMyOx/ARIqKl4V0uiizKMyAfGfrpWMv7/8mk2NswW2yK/4hhGx+mkPoE8sKg7TIyKa6riNXZYwOhHUDV6M3dAbI17ou9pZT4DaOYw+kG5ralsLoAriydAUSR9eqJlcQXQEz8zANzDNOWuhIVklkd1ly8qJb4MHco2eegG9Lu74uC/2T+9MJwGsDkCz0Wf41FjpoIL9Cr630gUTNg+kEuMtA8HeDG4h4B9lC6aNFmYfngwfrV4H0tub1+O49CnK0qFE4XZ2AeZyediBGL3QcRzLxNx1dlO9IDFzpkYR2nWmlczn52joxAidkq3P3qmN60aW7gn8ZvVNKlSSVqqa9vb1/UskmBWEYCKMjKEioELSld1BcKIiCguUD7Y+5/3lciM4MaaWZvl2Y5qV9TQ9+IbjKxZmmscnuTIsgVtmGpjDLwK4AoPutGkxyewDN19UBejnF7SHeswGUO4DdNjOCNqP9hbe7Pas+Ju0G2G0zA9LM39GA3QYzxw1gPvk7WN1eitjDpwUY3R5My7+Q3bJQdjaagQ4RrT7N01jcXHFYxhzUEzdHYykqyfMe86okOY1np3bWkblW84JSKOXWR6R+yvGWknDrinn97VFSIu74p8hDzNaOUslXoshwj6OjdC7DRXiyyslCERWJe+zJRslFBnoUZOXUX6T/clivYN3X491OGewmCERR9M6b1YSYCSYS2BAXbtS4Mk3qyrwFuOj//0/fAzuiUGo7uConRnJRb/B68A0RnBYDN3s9qN2fFfzo3+SLE+JY9Rapgnax7B8XqSPl6Cv4sMceU7C8U7vqaxerYN3901uiQ7wmYY+g3STdt0WqvhzxCtZhj3dMye6q9mVIu3gFq3aPHaZFNal1D9Vu8u6FLHIZliNewepcL7R5eg71WbR7Dav6gJl/jWHFIuMcR94gLTN2BG4AsYEQktUPUBstK2as2hKRx5oLeQCFZMde6oWv6pDYw7An0hOWSUjGqgkNtrmENZdAYj1bKNfqkJyDYdOesPzUICR9GW9DF1ixGu+SZ9+pVvDTIAbImY+daqczabxLcK5TTQKeGKRgx2v49gvQN4PAc/mLQUojYMPWsEvTLNusCyYujJASb+SQhAQ4vlUbYfRnbEiyLMFW1s6dpBLcYIgVCgnwnWplZJHUNKQmB5on5Hol17OJUZKQAMgR0Dfm7WuYmZmZmXkRn1OFmFtAC0JeAAAAAElFTkSuQmCC");
  background-size: contain;
}

.payday .CheckoutFooter-top-item--logo-ebit .ImgEbitDiamante img {
  display: none;
}

.black-friday .CheckoutFooter-top-item--logo-ebit .ImgEbitDiamante img {
  display: none;
}

.CheckoutFooter-top {
  margin-bottom: 20px;
  padding-top: 20px;

  text-align: center;
}

.CheckoutFooter-top-item {
  display: inline-block;

  padding-right: 15px;
  padding-left: 15px;
}

.CheckoutFooter-section {
  padding-bottom: 20px;

  text-align: center;
}

.CheckoutFooter-icon {
  width: 25px;
  height: 25px;

  vertical-align: middle;
}

.payday .CheckoutFooter-icon {
  fill: $color-white;
}

.black-friday .CheckoutFooter-icon {
  fill: $color-white;
}

.fantastic-sale .CheckoutFooter-icon {
  fill: $color-fantastic-sale-text-footer;
}

.CheckoutFooter-logo-internetSegura {
  width: 61px;
  height: 23px;

  vertical-align: middle;
}

.CheckoutFooter-logo-ebit {
  width: 50px;
  height: 20px;

  vertical-align: middle;
}

.EbitDiamante-icon {
  width: 62px;
  height: 62px;
}

@include desktop {
  .CheckoutFooter {
    font-size: 14px;
  }
  .CheckoutFooter-content {
    width: $desktop-width;
    margin-right: auto;
    margin-left: auto;
  }
  .CheckoutFooter-top {
    display: flex;

    justify-content: center;

    line-height: 30px;
  }
}
