body {
  overflow-y: scroll;

  color: $color-mine-shaft;
  font-family: Arial, Helvetica, "Liberation Sans", Roboto, sans-serif;
  line-height: $line-height-normal;

  &:has(.OverScreen.show) {
    overflow: hidden;
  }
}

a {
  color: $color-gray;
}

.no-scroll {
  overflow: hidden;
}

.flow-controll-modal {
  overflow-x: hidden;
  overflow-y: hidden;
}

.clearfix {
  @include clearfix();
}

/* CUSTOM */
button {
  padding: 0;

  cursor: pointer;
}

select {
  background-color: $color-white;
}

iframe {
  margin: 0;
  padding: 0;

  border: 0;

  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

* {
  box-sizing: border-box;
}
