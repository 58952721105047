@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

@import "style/resources.scss";

.NavigationOption {
  &-container {
    display: flex;
    flex-direction: column;
  }

  &-shortcut {
    display: flex;
    align-items: center;
  }

  &-button {
    background: none;
    border: 2px solid;
    margin: 0;
    padding: 14px;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    @include mobile {
      border: 1px solid;
      padding: 5px;
      width: 32px;
      height: 32px;
    }

    &-neutral {
      border-color: $color-gray;
    }

    &-passed {
      border-color: $color-apple;
    }

    &-current {
      border-color: $color-azure-radiance;
    }
  }

  &-progress {
    border: 0.5px solid;
    height: 1px;
    margin-bottom: 2px;
    padding: 0;
    width: 56px;
    @include mobile {
      border-bottom: 0;
      width: 36px;
    }

    &-neutral {
      border-color: $color-gray;
    }

    &-passed {
      border-color: $color-apple;
    }

    &-current {
      border-color: $color-azure-radiance;
    }
  }
  &-progress-wallet {
    border: 0.5px solid;
    height: 1px;
    padding: 0;
    width: 56px;
    @include mobile {
      border-bottom: 0;
      width: 36px;
    }

    &-neutral {
      border-color: $color-gray;
    }

    &-passed {
      border-color: $color-apple;
    }

    &-current {
      border-color: $color-azure-radiance;
    }
  }

  &-title {
    font: var(--tom-font-3xsm-medium);
    margin-top: 4px;
    @include mobile {
      font: var(--tom-font-3xsm-regular);
    }

    &-sacola {
      margin-left: 15px;
      @include mobile {
        margin-left: 0;
      }
    }

    &-identificacao {
      margin-left: -3px;
      @include mobile {
        margin-left: -18px;
      }
    }

    &-entrega {
      margin-left: 11px;
      @include mobile {
        margin-left: -4px;
      }
    }

    &-pagamento {
      margin-left: 1px;
      @include mobile {
        margin-left: -14px;
      }
    }

    &-revisao {
      margin-left: 13px;
      @include mobile {
        margin-left: -5px;
      }
    }

    &-neutral {
      color: $color-gray;
    }

    &-passed {
      color: $color-apple;
    }

    &-current {
      color: $color-azure-radiance;
    }
  }
}
