@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.PaymentPage-PJWarning {
  padding: $mobile-spacing;

  color: $color-california;
}

.PaymentPage-title {
  @extend %page-title;
}

.PaymentContract-link {
  display: block;

  margin-bottom: 20px;
}

@include mobile {
  .PaymentPage-title {
    margin-left: 15px;
    color: var(--tom-color-on-surface-1);
  }
}
