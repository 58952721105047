@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.SignupForm,
.EditRegistrationPage {
  @include desktop {
    margin-top: 20px;
    .AddressForm {
      flex-flow: column;
      margin-bottom: 20px;
      .AddressForm-form-group {
        margin-left: 195px;
      }
      .FormGroup-label {
        margin-left: -195px;
      }
    }
    .continueButton {
      margin-left: 367px;
    }
  }
  @include mobile {
    padding-top: 6px;
  }
}

.hide {
  display: none;
  visibility: hidden;
}

.SignupPolicy {
  @include desktop {
    display: inline-block;
    margin-bottom: 25px;
  }
  @include mobile {
    display: contents;
  }
}
