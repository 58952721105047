@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.TagInternational-tag {
  display: inline-flex;
  justify-content: center;
  border-radius: var(--tom-radius-sm);
  gap: var(--tom-spacing-2xsm);
  font: var(--tom-font-2xsm-regular);
  padding-bottom: var(--tom-spacing-2xsm);
  padding-left: var(--tom-spacing-xsm);
  padding-right: var(--tom-spacing-xsm);
  padding-top: var(--tom-spacing-2xsm);

  background-color: var(--tom-color-interaction-lighter-inverted);
  border-color: var(--tom-color-interaction-lighter-inverted);
  color: var(--tom-color-brand-default);

  &-bold {
    font: var(--tom-font-2xsm-bold);
  }
}
