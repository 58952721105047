@import "../../../../../style/variables/colors.scss";
@import "../../../../../style/variables/typography.scss";
@import "../../../../../style/variables/forms.scss";
@import "../../../../../style/variables/general.scss";
@import "../../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../../style/utils/helpers";
@import "../../../../../style/utils/responsive";
@import "../../../../../style/utils/typography";
@import "../../../../../style/utils/list";
@import "../../../../../style/utils/buttons";
@import "../../../../../style/utils/inputs";
@import "../../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../../style/components/placeholders/GenericBox";

.PaymentForm-form-dropdown {
  @extend %default-input;
  // margin-right: 20px;

  width: 224px;
  height: 40px;
}

.PaymentForm-form-dropdown--withError {
  @extend .PaymentForm-form-dropdown;

  border-color: $color-cinnabar;
}

.PaymentForm-form-dropdown--half {
  @extend .PaymentForm-form-dropdown;

  width: 93px;
  height: 40px;

  & + .FormGroup-errorMessage {
    width: 93px;

    white-space: nowrap;
  }
}

.PaymentForm-form-dropdown--half--withError {
  @extend .PaymentForm-form-dropdown--half;

  border-color: $color-cinnabar;
}

.PaymentForm-form-dropdown:disabled {
  @extend %default-input-disabled;
}

.BankSlipForm-samedaymessage {
  max-width: 625px;
  margin-bottom: 18px;

  color: $color-california;
  line-height: 1.6;
}

.BankSlipForm-price,
.PixForm-price {
  margin: 30px 0 20px;

  font-weight: $font-weight-bold;
}

.BankSlipForm-price--highlighted,
.PixForm-price--highlighted {
  color: $color-lochmara;
}

.BankSlipForm-description,
.PixForm-description {
  margin-bottom: 10px;
}

.SavedCardForm {
  padding-top: 20px;
}

.ValeTrocaCreditForm-messages {
  max-width: 625px;
  margin-bottom: 10px;
  margin-left: 30px;

  line-height: $line-height-normal;
}

.ValeTrocaCreditForm-money {
  color: $color-apple;
}

.ValeTrocaCreditForm-error {
  color: $color-cinnabar;
}

.ValeCompraForm-remaining-credit {
  padding-bottom: 10px;
}

.ValeCompraForm-remaining-amount {
  color: $color-lochmara;
}

.ValeCompraForm-info {
  padding-bottom: 10px;
}

.ValeCompraForm-info-third-party-items {
  padding-bottom: 20px;
}

.ValeCompraForm-info-third-party-items-warning {
  color: $color-cinnabar;
}

@mixin PaymentForm-form-dropdown {
  width: 240px;
  height: 52px;
}

@include mobile {
  .PaymentBox {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }

  .PaymentPage-title {
    margin-left: 15px;
    padding-left: 0;
  }

  .PaymentForm-form-dropdown {
    @include PaymentForm-form-dropdown();
  }

  .PaymentForm-form-dropdown--half {
    @include PaymentForm-form-dropdown();

    width: 118px;
    height: 52px;
  }

  .PaymentForm-form-checkboxGroup {
    width: 90%;
    margin-left: 0;
  }

  .BankSlipForm-description,
  .PixForm-description {
    margin-top: 15px;

    line-height: $line-height-normal;
  }

  .BankSlipForm,
  .PixForm,
  .SavedCardForm {
    width: 285px;
    margin: 0 auto;
    padding-bottom: 20px;
  }

  .PaymentModal {
    background-color: $color-wild-sand;
  }

  .PaymentForm-form-cardFlagsBox {
    margin-top: 20px;
  }

  .ValeCompraForm-remaining-credit-next {
    display: block;
  }
}

.PaymentExpirationItem {
  @include mobile {
    justify-content: space-between;
  }

  display: flex;

  margin-bottom: 20px;
}

.PaymentExpirationItem-group {
  position: relative;

  display: flex;

  &:first-child {
    margin-right: 20px;
  }
}

@include desktop {
  .BankSlipForm-samedaymessage {
    margin: 10px auto;

    text-align: center;
  }

  .BankSlipForm-price,
  .PixForm-price {
    margin-left: 320px;
  }

  .BankSlipForm-price--highlighted,
  .PixForm-price--highlighted {
    color: $color-lochmara;

    margin-left: 46px;
  }

  .BankSlipForm-description,
  .PixForm-description {
    margin-left: 366px;
  }

  .ValeTrocaCreditForm-messages {
    margin-left: $form-left-space + $desktop-spacing;
  }

  .NewCardForm .FormGroup--multiple .FormGroup:first-child,
  .VirtualDebitEloForm .FormGroup--multiple .FormGroup:first-child {
    width: 463px;
    margin-right: 20px;
  }

  .NewCardForm .FormGroup--multiple .FormGroup:nth-child(2),
  .VirtualDebitEloForm .FormGroup--multiple .FormGroup:nth-child(2) {
    width: 97px;
  }

  .NewCardForm .FormGroup-label,
  .VirtualDebitEloForm .FormGroup-label {
    width: $form-left-space;
  }

  .NewCardForm .checkboxGroup,
  .VirtualDebitEloForm .checkboxGroup {
    margin-left: $form-left-space;
  }

  .BankSlipForm .continueButton,
  .PixForm .continueButton,
  .ValeTrocaCreditForm .continueButton,
  .SavedCardForm .continueButton,
  .NewCardForm .continueButton,
  .VirtualDebitEloForm .continueButton {
    margin-left: $form-left-space + $desktop-spacing;
  }

  .NewCardForm .FormGroup--multiple .FormGroup-inputGroup,
  .VirtualDebitEloForm .FormGroup--multiple .FormGroup-inputGroup {
    width: 97px;
  }
}

.VirtualDebitEloForm-limit-warning {
  @extend %text-info;
  @include mobile {
    text-align: center;
  }
  @include desktop {
    margin-left: $form-left-space + $desktop-spacing;
  }
}
