@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.CustomerAreaPage {
  padding: 80px 0 240px 0;
  display: flex;
  justify-content: center;
  background-color: var(--tom-color-on-surface-9);
  font-family: var(--tom-font-family);

  &-success {
    width: fit-content;
    padding: 16px;
    margin: 0 auto 32px;
    border-radius: 8px;
    border: solid 1px var(--tom-color-success-default);
    background-color: var(--tom-color-on-success-lighter);
    display: flex;

    .ti-check-circle {
      margin: 0 12px 0 0;
      align-items: center;
    }

    .ti-check-circle::before {
      font-size: 24px;
      color: var(--tom-color-success-default);
    }

    span {
      font-size: 16px;
      font-weight: 400;
      vertical-align: middle;
    }
  }

  &-warn {
    width: fit-content;
    padding: 16px;
    margin: 0 auto 32px;
    border-radius: 8px;
    border: solid 1px #ccac00;
    background-color: #fffdf0;
    display: flex;

    .ti-warning-amber {
      margin: 0 12px 0 0;
      align-items: center;
    }

    .ti-warning-amber::before {
      font-size: 24px;
      color: #ccac00;
    }

    span {
      font-size: 16px;
      font-weight: 400;
      vertical-align: middle;
    }
  }

  &-container {
    background-color: var(--tom-color-on-brand-default);
    border-radius: 12px;
    width: 504px;
  }

  &-content {
    margin-top: 40px;
    padding: 0 40px 0 40px;

    h2 {
      margin: 0;
      padding: 0;
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      color: var(--tom-color-on-surface-2);
      margin-bottom: 30px;
    }

    &-option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      a {
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: var(--tom-color-interaction-default);
        text-decoration: none;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-description {
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: var(--tom-color-on-surface-2);
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: var(--tom-color-on-surface-2);
      }
    }
  }
}

@include mobile {
  .CustomerAreaPage {
    padding: 24px 0 240px 0;
    background-color: unset;

    &-content {
      padding: 0 16px 0 16px;
      margin: 0;
    }
  }
}
