@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

@import "../../../style/resources.scss";

.ConfirmOrderHeader {
  margin-bottom: 20px;
}

.ConfirmOrderHeader-title {
  @extend %page-title;
}

.ConfirmOrderHeader-title--success {
  @extend .ConfirmOrderHeader-title;

  color: $color-apple;
}

.ConfirmOrderHeader-title--error {
  color: $color-cinnabar;
}

.ConfirmOrderHeader-title-icon {
  width: 22px;
  height: 22px;

  vertical-align: middle;

  fill: $color-apple;
}

.ConfirmOrderHeader-title-icon-check {
  display: block;
  margin: 0 auto 18px;
}

.ConfirmOrderHeader-description {
  padding-bottom: 20px;
}

.ConfirmOrderHeader-description--warning {
  @extend .ConfirmOrderHeader-description;

  color: $color-california;
}

.ConfirmOrderHeader-email {
  color: $color-lochmara;
}

.ConfirmOrderFooter-legalTerms {
  @extend %text-info;

  margin-top: 20px;
  margin-bottom: 20px;

  text-align: center;
}

.Banner-epoca {
  float: left;

  width: 100%;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: -7px;
}

.Banner-adx {
  float: left;

  width: 100%;
  margin: 20px 0;

  text-align: center;
}

.Banner-Ebit-container {
  display: flex;

  width: 100%;

  text-align: center;
}

.Banner-Ebit {
  width: 100%;
}

.Banner-Affinion-container {
  float: left;

  width: 100%;
  margin-top: 20px;
  margin-right: -2 * 20px;
  margin-bottom: 20px;
  margin-left: 0;
  padding-right: 20px * 2;
}

@include mobile {
  .ConfirmOrderHeader-email {
    color: inherit;
    font-weight: $font-weight-bold;
  }

  .ConfirmOrderHeader-title--error,
  .ConfirmOrderHeader-title--success {
    margin: 20px;

    line-height: $line-height-normal;
    text-align: center;
  }
}
