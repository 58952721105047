@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.PaymentWithoutInternationalTax {
  text-align: center;
  max-width: 490px;

  &-header {
    margin-bottom: 16px;
    .font-lg-bold {
      font: var(--tom-font-lg-bold);
    }
  }

  &-content {
    margin-bottom: 24px;

    .font-sm-regular {
      font: var(--tom-font-sm-regular);
    }
  }

  &-btn {
    padding: 15px 58px;
    margin: 0 auto;
    display: block;
    width: fit-content;
  }
}

.ModalDefault-drawer {
  .ModalDefault {
    &-header {
      padding: 16px;
      justify-content: center;
      display: none;
    }
  }
}

@include mobile {
  .ModalDefault-drawer {
    .ModalDefault {
      &-content {
        width: 80%;
        top: 0;
        right: 0;
        left: unset;
        transform: none;
        border-radius: 0;
      }

      &-header {
        padding: 16px;
        justify-content: flex-start;
        display: flex;
        .ti-arrow-back {
          display: flex;
        }

        .icon-close {
          display: none;
        }
      }

      &-body {
        height: 100vh;
        overflow: auto;
      }
    }
  }
  .PaymentWithoutInternationalTax {
    &-header {
      display: none;
    }
    &-content {
      flex-direction: column;
    }

    .font-sm-regular {
      width: 100%;
    }
  }
}
