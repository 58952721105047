@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.BobbyCarousel {
  padding-top: 44px;
}

.BobbyCarousel:last-of-type {
  padding-bottom: 8px;
}

.BobbyCarousel--larger {
  margin-right: 20px;
  padding-top: 50px;
}

.BobbyContainer,
.BobbyContainer--compact {
  padding-top: 2px;
}

.BobbyCarousel--larger:last-of-type .BobbyContainer,
.BobbyCarousel--larger:last-of-type .BobbyContainer--compact {
  padding-bottom: 22px;
}

.BobbyContainer-title {
  margin-left: 12px;

  color: $color-lochmara;
}

.BobbyContainer-title--bigger {
  @extend .BobbyContainer-title;

  font-size: 24px;
}

/*
 * Bobby subject
 */
.stewie-bold {
  font-weight: $font-weight-bold;
}

/*
 * react-slider
 */

.slick-slider {
  position: relative;
}

.slick-list {
  overflow: hidden;

  width: 100%;
  padding: 10px;
  padding-bottom: 25px;
}

.slick-track {
  display: flex;

  align-items: stretch;
}

.slick-slide {
  display: flex;
  float: left;
}
.slick-slide > div {
  display: flex;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button::before {
  font-size: 25px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button::before {
  opacity: 0.75;
}

@include desktop {
  .BobbyCarousel {
    margin-right: 20px;
    padding-top: 20px;
  }

  .BobbyContainer .slick-slide > div:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0 0 20px;
  }
}

@include mobile {
  .BobbyContainer-title {
    margin-left: 12px;

    text-align: left;
  }
}
