.CloseIcon {
  @include CloseIcon();
  @include mobile {
    top: 15px;
    right: 16px;

    width: 18px;
    height: 18px;
  }
}
