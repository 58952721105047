@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

@import "../../../../style/resources.scss";

.CheckboxGroup {
  @include desktop {
    display: flex;

    position: relative;

    margin-bottom: 10px;
    margin-left: $form-left-space;
    padding-left: 20px;
  }

  margin-right: 10px;
  margin-bottom: 20px;
}

.CheckboxGroup-input {
  position: absolute;

  width: 20px;
  height: 20px;
}

.checked--icon {
  width: 16px;
  height: 15px;
  margin-top: 1px;
  margin-left: 1px;

  fill: $color-white;
}

.unchecked--icon {
  visibility: hidden;
}

.CheckboxGroup--state-checked {
  position: absolute;

  width: 20px;
  height: 20px;
  margin-right: 10px;

  border: $color-lochmara solid 1px;
  border-radius: $border-radius-size;
  background: $color-lochmara;

  transition: all 0.125s ease;
}

.CheckboxGroup--state-unchecked {
  @extend .CheckboxGroup--state-checked;

  border-color: $color-gray;
  background: $color-white;

  transition: all 0.125s ease;
}

.CheckboxGroup-labelText {
  margin-left: 30px;
}
