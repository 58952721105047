@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.PaymentFailModal {
  text-align: center;
}

.PaymentFailModal-title-icon {
  width: 77px;
  height: 54px;
  margin: 0 auto;
}

.PaymentFailModal-title {
  @extend %modal-title;
}

.PaymentFailModal-subtitle {
  margin-bottom: 20px * 2;

  color: $color-lochmara;
}

.PaymentFailModal-ReasonsWrapper {
  display: flex;
}

.PaymentFailModal-ReasonIcon {
  display: block;

  width: 157px;
  height: 92px;
  margin: 0 auto 20px auto;
}

.PaymentFailModal-ReasonText {
  padding: 0 10px;
}

.PaymentFailModal-content {
  margin-top: 40px;
  margin-bottom: 20px;

  color: $color-lochmara;
}

.PaymentFailModal-RetryPaymentWrapper {
  display: flex;

  justify-content: space-around;

  margin-bottom: 40px;
}

.PaymentFailModal-RetryPaymentItem {
  cursor: pointer;
  text-decoration: underline;
}

.PaymentFailModal-RetryPaymentItem--NewCard {
  @extend .PaymentFailModal-RetryPaymentItem;
}

.PaymentFailModal-RetryPaymentItem--NewCardLarge {
  @extend .PaymentFailModal-RetryPaymentItem--NewCard;

  margin-left: 20px;
}

.PaymentFailModal-RetryPaymentItem--BankSlip {
  @extend .PaymentFailModal-RetryPaymentItem;
}

.PaymentFailModal-RetryPaymentIcon {
  width: 50px;
  height: 30px;
  padding-right: 10px;
  padding-left: 10px;

  vertical-align: middle;

  fill: $color-mine-shaft;
}

.PaymentFailModal-ReceiveCallText {
  max-width: 540px;
  margin: 0 auto;

  color: $color-lochmara;
  font-size: 15px;
  line-height: $line-height-biggest;
}

.PaymentFailModal-continue {
  @extend %secondary-button;

  width: 224px;
}

.PaymentFailModal-continue:hover {
  @extend .PaymentFailModal-continue;

  background-color: $color-bahama-blue;
}

.PaymentFailModal-continue:active {
  @extend .PaymentFailModal-continue;

  background-color: $color-bahama-dark;
}

@include mobile {
  .PaymentFailModal-title {
    box-sizing: border-box;
    margin-top: 50px;
    padding: 10px;
  }

  .PaymentFailModal-RetryPaymentItem {
    margin-bottom: $mobile-spacing;
  }

  .PaymentFailModal-RetryPaymentWrapper {
    margin-bottom: 20px;
  }

  .PaymentFailModal-titleWrapper {
    margin-top: 20px;
  }

  .PaymentFailModal-ReasonsWrapper {
    flex-direction: column;
  }

  .PaymentFailModal-ReasonItem,
  .PaymentFailModal-ReasonItem--bigger {
    margin-bottom: 20px;
  }

  .PaymentFailModal-RetryPaymentItem--NewCardLarge {
    margin-left: 0;
  }

  .PaymentFailModal-ReceiveCallText {
    box-sizing: border-box;
    width: 100%;
    padding: $mobile-spacing;

    line-height: $line-height-normal;
  }

  .PaymentFailModal-continue {
    margin: 0 auto;
    margin-bottom: 20px;

    text-align: center;
  }
}
