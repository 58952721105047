@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

$label-width: 180px;

.OrderReviewTotals {
  font-size: 14px;
}

.OrderReviewTotals-total {
  padding-top: 20px;

  font-weight: $font-weight-bold;
}

.OrderReviewTotals-products,
.OrderReviewTotals-services {
  display: flex;
}

.OrderReviewTotals-right,
.OrderReviewTotals-right--servicesAmount {
  color: $color-lochmara;
}

.OrderReviewTotals-right--free {
  color: $color-apple;
  font-weight: $font-weight-bold;
}

.OrderReviewTotals--promocode {
  color: $color-apple;
}

.OrderReviewTotals--loyalty {
  color: $color-rouge;
}

.OrderReviewTotals-international {
  &-tax {
    margin-bottom: 10px;

    &-text {
      font: var(--tom-font-sm-regular);
      cursor: pointer;
    }

    &-item {
      align-items: center;

      .OrderReviewTotals-left {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 5px;
      }
      .OrderReviewTotals-right {
        font-weight: $font-weight-bold;
      }
    }
    &-icms {
      .OrderReviewTotals-left {
        font-size: 15.6px;
      }
    }
  }
}

.OrderReviewTotals-shipment {
  margin-bottom: 10px;
}

.OrderReviewTotal__to {
  font-weight: $font-weight-normal;
}

@include mobile {
  .OrderReviewTotals-products,
  .OrderReviewTotals-services,
  .OrderReviewTotals-shipment,
  .OrderReviewTotals-international-tax-item,
  .OrderReviewTotals-discount,
  .OrderReviewTotals-total {
    display: flex;

    justify-content: space-between;
  }

  .OrderReviewTotals-right,
  .OrderReviewTotals-right--servicesAmount {
    color: var(--tom-color-on-surface-1);
    font-weight: $font-weight-bold;
  }

  .OrderReviewTotal__to {
    font-weight: $font-weight-bold;
  }

  .OrderReviewTotals-left,
  .OrderReviewTotals-left--servicesQuantity {
    padding-left: 15px;
  }

  .OrderReviewTotals-right,
  .OrderReviewTotals-right--free,
  .OrderReviewTotals-right--servicesAmount {
    padding-right: 15px;

    text-align: right;
  }

  .OrderReviewTotals-total .OrderReviewTotals-left {
    font-size: 16px;
  }

  .OrderReviewTotals-international-tax-item {
    .OrderReviewTotals-left {
      flex-direction: row-reverse;
    }
  }

  .OrderReviewTotals-discount__minus {
    padding-right: 8px;
  }
}

@include desktop {
  .OrderReviewTotals-shipment,
  .OrderReviewTotals-international-tax-item,
  .OrderReviewTotals-discount,
  .OrderReviewTotals-total {
    display: inline-flex;

    width: 100%;
  }

  .OrderReviewTotals-left,
  .OrderReviewTotals-left--servicesQuantity {
    width: $label-width;
    margin-right: 20px;

    text-align: right;
  }

  .OrderReviewTotals .CardLuiza {
    width: 100%;
  }

  .OrderReviewTotals .CardLuiza__icon-cardluiza {
    width: 227px;

    text-align: right;
  }

  .OrderReviewTotals-discount__minus {
    display: inline-flex;

    width: 8px;
    margin-left: -8px;
  }
}
