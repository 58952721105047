@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.Modal.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.Modal.inset-x-0 {
  top: 0;
  right: 0;
}

.Modal-overlay {
  cursor: auto;
  background-color: var(--tom-color-on-surface-2);
  opacity: var(--tom-opacity-20);
  position: fixed;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
}
