@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.SignupNotificationsPJ {
  margin-bottom: 40px;
  @include desktop {
    margin-top: -40px;
  }
}

.SignupNotificationsPJ-title {
  @include desktop {
    font-size: 18px;
  }
}

.SignupNotificationsPJ-description {
  display: block;

  margin-bottom: 25px;
  @include desktop {
    margin-left: 366px;
  }
}

.SignupNotificationsPJ-icon {
  margin-right: 6px;
}

.SignupNotificationsPJ-iconDescription {
  font-weight: $font-weight-medium;
}

.SignupNotificationsPJ-checkboxGroup,
.SignupNotificationsPJ-title {
  display: inline-flex;

  align-items: center;
  @include desktop {
    width: 373px;
    margin-top: 25px;
    margin-bottom: 10px;
    margin-left: $form-left-space;
    padding-left: 20px;
  }
  @include mobile {
    margin-right: 10px;
    margin-bottom: 20px;
  }
}
