@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.SimpleLoader-wrapper {
  display: flex;
  backdrop-filter: blur(6px);
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: $z-index-extra-high;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color-white, 0.35);
}

.SimpleLoader-wrapper span {
  margin-left: 5px;
  font-size: 1rem;
  color: $color-azure-radiance;
  font-weight: $font-weight-bold;
}

.SimpleLoader-circle {
  border: 3px solid $color-mercury;
  border-radius: 50%;
  border-top: 3px solid $color-azure-radiance;
  width: 18px;
  height: 18px;
  -webkit-animation: spin 1.2s linear infinite;
  animation: spin 1.2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
