
/*
 * Font-weight
 * https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
 */
$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-heavy: 900;

/*
  * Line-height
  */
$line-height-smaller: 1;
$line-height-smallest: 1.2;
$line-height-small: 1.3;
$line-height-normal: 1.5;
$line-height-big: 2;
$line-height-biggest: 40px;
$line-height-inherit: inherit;

/*
  * Font-size
  */
$font-size-info: 12px;
$font-size-link: 14px;
$font-size-marker-info: 16px;
