@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.CardFlagsBox {
  display: flex;
  flex-wrap: wrap;

  align-items: center;
}

.CardFlagContainer {
  opacity: 1;

  cursor: pointer;
}

.CardFlagContainer--notChosen {
  @extend .CardFlagContainer;

  opacity: 0.15;
}

@include mobile {
  .CardFlagsBox {
    margin-top: 40px;
    margin-left: 13px;
    margin-bottom: 13px;
  }
}
