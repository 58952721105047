.form-title {
  @include mobile {
    margin: 0;
    padding-bottom: $mobile-spacing;

    font-size: 14px;
  }

  margin-bottom: 20px;
  margin-left: $form-left-space + $desktop-spacing;

  font-size: 18px;
  font-weight: $font-weight-bold;
}

.checkboxGroup {
  @include desktop {
    margin-bottom: 10px;
    margin-left: $form-left-space;
    padding-left: 20px;
  }

  margin-right: 10px;
  margin-bottom: 20px;
}

.checkbox {
  margin-right: 10px;
}

.checkbox-label {
  width: 90%;
}

.continueButton {
  @extend %continue-button;
  @include mobile {
    width: 85%;
    margin-left: 20px;
  }

  margin-bottom: 20px;
}

.continueButton--withCancel {
  @extend .continueButton;
  @include mobile {
    width: 100%;
    margin-left: 20px;
  }
  @include desktop {
    margin-left: 0;
  }

  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;

  background-color: $color-apple;
}

.continueButton--withCancel:hover {
  @extend .continueButton--withCancel;

  background-color: $color-la-palma;
}

.continueButton--withCancel:active {
  @extend .continueButton--withCancel;

  background-color: $color-la-palma-dark;
}

.inputWithinGroup {
  @include mobile {
    display: flex;

    flex-flow: wrap;
    justify-content: center;

    text-align: center;
  }
  @include desktop {
    width: 70%;
  }
}

.inputWithin {
  @extend %input-within;
  $input-width: 85%;
  @include mobile {
    width: $input-width;
    height: $mobile-input-height;
    margin-left: 0;
  }

  margin-bottom: 10px;
  padding-top: 2px;
}

.inputWithin--withError {
  @extend %input-within;

  border-color: $color-cinnabar;
}

.buttonWithin {
  @extend %button-within;
}

.Success {
  @include mobile {
    display: none;
  }

  align-self: flex-start;

  width: 35px;
  height: 15px;
  margin-top: 10px;

  fill: $color-forest-green;
}
