@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.CardFlag {
  @include mobile {
    margin-top: 6px;
  }

  width: 46px;
  height: 30px;
  margin-right: 10px;
}

.PaymentForm-cardFlagsBox > .CardFlag {
  cursor: pointer;
}

.CardFlag-smartphone {
  @extend .CardFlag;
  @include mobile {
    margin-top: 0;
    width: 45px;
    height: 45px;
  }
  @include desktop {
    width: 22px;
    height: 22px;
  }
}

.CardFlag-cvv-card {
  @extend .CardFlag;
  @include mobile {
    margin-top: 0;
    width: 120px;
    height: 50px;
  }
  @include desktop {
    width: 60px;
    height: 30px;
  }
}
