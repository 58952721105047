@import "variables/colors.scss";
@import "variables/typography.scss";
@import "variables/forms.scss";
@import "variables/general.scss";
@import "variables/layout.scss";

/*
 * Utils
 */

@import "utils/helpers";
@import "utils/responsive";
@import "utils/typography";
@import "utils/list";
@import "utils/buttons";
@import "utils/inputs";
@import "utils/icons";

/*
 * Components placeholders
 */

@import "components/placeholders/GenericBox";

@tailwind base;
@tailwind components;
@tailwind utilities;
