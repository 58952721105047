@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.DeliveryModeButtons-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 1rem auto 0;
  flex-direction: column;

  button:nth-child(1) {
    margin-bottom: 12px;
  }
}

.DeliveryModeButtons-btn {
  padding: 18px;
  font-size: 14px;
  background-color: $color-white;
  border-radius: $border-radius-medium;
  width: 100%;
  max-width: 328px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 20px 0 #5755551f;
  @include mobile {
    max-width: unset;
  }
}

.DeliveryModeButtons-btn-content {
  display: flex;
  justify-content: start;
  align-items: center;
  svg {
    margin: 0 15px 0 0 !important;
  }
  :nth-child(2) {
    text-align: left;
    max-width: 210px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  @include mobile {
    max-width: 88%;
  }
}

.DeliveryModeButtons-btn-info {
  color: $color-azure-radiance;
}

.DeliveryModeButtons-svg-hidden {
  width: 20px;
  height: 20px;
  margin-right: 10px !important;
  margin-bottom: 0 !important;
  color: var(--tom-color-brand-default);
  font-size: 40px;
  display: flex;
  align-items: center;
}

.DeliveryModeButtons-btn__svg {
  width: 24px;
  height: 24px;
  font-size: 26px;
  margin-right: 15px !important;
  margin-bottom: 0 !important;
  color: var(--tom-color-brand-default);
}
