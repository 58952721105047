@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

@import '../../../../style/resources.scss';

.CheckoutSteps {
  display: flex;

  align-items: center;

  margin-top: -6px;
}

.CheckoutSteps-item {
  margin-left: 10px;

  font-size: 14px;
}

.CheckoutSteps-item-button {
  border: 0;
  background: none;
}

.CheckoutSteps-item-title {
  color: $color-science-blue;
  vertical-align: middle;
}

.header-pink .CheckoutSteps-item-title {
  color: $color-hibiscus;
}

.payday .CheckoutSteps-item-title {
  color: $color-dove-gray;
}

.black-friday .CheckoutSteps-item-title {
  color: $color-dove-gray;
}

.CheckoutSteps-item-title--active {
  @extend .CheckoutSteps-item-title;

  color: $color-white;
}

.header-pink .CheckoutSteps-item-title--active {
  @extend .CheckoutSteps-item-title;

  color: $color-white;
}

.payday .CheckoutSteps-item-title--active {
  @extend .CheckoutSteps-item-title;

  color: $color-white;
}

.black-friday .CheckoutSteps-item-title--active {
  @extend .CheckoutSteps-item-title;

  color: $color-white;
}

/*
 * Icons
 */

.CheckoutSteps-item-icon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  margin-left: 10px;

  vertical-align: middle;

  fill: $color-science-blue;
}

.header-pink .CheckoutSteps-item-icon {
  fill: $color-hibiscus;
}

.payday .CheckoutSteps-item-icon {
  fill: $color-dove-gray;
}

.black-friday .CheckoutSteps-item-icon {
  fill: $color-dove-gray;
}

.CheckoutSteps-item-icon--active {
  @extend .CheckoutSteps-item-icon;

  fill: $color-white;
}

.header-pink .CheckoutSteps-item-icon--active {
  @extend .CheckoutSteps-item-icon;

  fill: $color-white;
}

.payday .CheckoutSteps-item-icon--active {
  @extend .CheckoutSteps-item-icon;

  fill: $color-white;
}

.black-friday .CheckoutSteps-item-icon--active {
  @extend .CheckoutSteps-item-icon;

  fill: $color-white;
}
