@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

@import "style/resources.scss";

.ButtonLink {
  @extend %button-link;
  @include mobile {
    margin-left: 10px;
  }
  color: var(--tom-color-interaction-default);
}

.ButtonLink__label {
  display: none;
  font-size: 16px;
  font-weight: $font-weight-normal;
  line-height: 18.4px;
}

.ButtonLink__icon {
  display: inline-block;
}

@include desktop {
  .ButtonLink__label {
    display: block;
    margin-top: 6px;
  }

  .ButtonLink__icon {
    display: none;
  }
}
