@import "../../../../../style/variables/colors.scss";
@import "../../../../../style/variables/typography.scss";
@import "../../../../../style/variables/forms.scss";
@import "../../../../../style/variables/general.scss";
@import "../../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../../style/utils/helpers";
@import "../../../../../style/utils/responsive";
@import "../../../../../style/utils/typography";
@import "../../../../../style/utils/list";
@import "../../../../../style/utils/buttons";
@import "../../../../../style/utils/inputs";
@import "../../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../../style/components/placeholders/GenericBox";

.CopyPix {
  text-align: center;
}

.CopyPix-input {
  @extend %default-input;

  margin-bottom: 10px;

  font-size: 16px;
}

.CopyPix-button {
  @extend %flex-continue-button;

  display: inline-flex;
}

.CopyPix-buttonn:hover {
  background-color: $color-la-palma;
}

.CopyPix-button:active {
  background-color: $color-la-palma-dark;
}

@include desktop {
  .CopyPix-input {
    opacity: 0;
    height: 0;
  }
}

@include mobile {
  .CopyPix {
    margin-top: 20px;
  }

  .CopyPix-input,
  .CopyPix-button {
    width: 100%;
  }
}
