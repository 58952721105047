/*
  * Z-index values
  */
$z-index-base: 0;
$z-index-low: 1;
$z-index-medium: 2;
$z-index-high: 20;
$z-index-extra-high: 30;

/*
  * Margin, padding, border, top and bottom
  */
$mobile-spacing: 10px;
$spacing-around: 16px;
$desktop-spacing: 20px;

/*
  * Height
  */
$mobile-input-height: 52px;
