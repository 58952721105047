@import "../../../../../style/variables/colors.scss";
@import "../../../../../style/variables/typography.scss";
@import "../../../../../style/variables/forms.scss";
@import "../../../../../style/variables/general.scss";
@import "../../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../../style/utils/helpers";
@import "../../../../../style/utils/responsive";
@import "../../../../../style/utils/typography";
@import "../../../../../style/utils/list";
@import "../../../../../style/utils/buttons";
@import "../../../../../style/utils/inputs";
@import "../../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../../style/components/placeholders/GenericBox";

.PixSteps-option {
  display: inline-block;
  width: 25px;
  align-self: flex-start;
  text-align: center;
  border: 1px solid $color-very-dark-gray;
  border-radius: 50%;
}

.OrderBoxPix {
  padding: 20px;
  margin-top: 20px;
  background-color: $color-wild-sand;
}

.OrderBoxPix-help {
  max-width: 300px;
  margin: 0 auto 20px;
  color: $color-very-dark-gray;
  text-align: center;
}

.OrderBoxPix-titleDescription,
.OrderBoxPix-information {
  display: flex;
  align-items: center;
  color: $color-very-dark-gray;
  font-weight: $font-weight-bold;
}

@include desktop {
  .PixSteps-option {
    margin-right: 10px;
  }

  .PixSteps-description {
    width: 80%;
  }

  .PixSteps,
  .PixSteps-block {
    display: inline-flex;

    margin-bottom: 10px;
  }

  .OrderBoxPix {
    @include rounded();
    margin-right: -20px;
    margin-left: -20px;
  }

  .OrderBoxPix-title {
    display: flex;
    margin-bottom: 20px;
  }

  .OrderBoxPix-titleIcon {
    margin-right: 10px;
  }

  .OrderBoxPix-information {
    flex-direction: column;
    margin-top: 20px;
  }
}

@include mobile {
  .PixSteps-option {
    margin-bottom: 10px;
  }

  .PixSteps-description {
    margin-bottom: 20px;
  }

  .OrderBoxPix {
    margin-bottom: 20px;
    text-align: center;
  }

  .OrderBoxPix-titleDescription {
    display: block;
    color: $color-black;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .OrderBoxPix-information {
    display: inline-flex;
    width: 80%;
    margin: 0;
  }

  .OrderBoxPix-help {
    max-width: none;
  }
}
