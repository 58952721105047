@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.ButtonShowPassword {
  border: 0;
  background: none;

  display: flex;

  position: absolute;

  align-items: center;
  justify-content: space-around;

  top: 0;
  @include desktop {
    margin-left: -50px;

    width: 40px;
    height: 40px;
  }
  @include mobile {
    right: 0;

    width: 51px;
    height: 52px;
  }
}
