@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

@import "../../../../style/resources.scss";

.FormGroup {
  @include mobile {
    flex-flow: column;
  }

  position: relative;

  display: flex;

  margin-bottom: 20px;
}

.FormGroup--last,
.FormGroup--thin {
  margin-bottom: 10px;
}

.FormGroup--multiple {
  @include desktop {
    .FormGroup-inputGroup {
      width: 40%;
    }

    .FormGroup-label {
      width: $form-left-space;
    }
  }

  display: flex;
}

@include mobile {
  .FormGroup--multiple {
    display: flex;

    justify-content: space-between;
  }

  .FormGroup--multiple .FormGroup {
    width: 48%;
  }
}

.FormGroup-label {
  @include desktop {
    display: inline-flex;

    align-items: center;
    justify-content: flex-end;

    width: $form-left-space;
    height: 40px;
    margin-right: 20px;

    line-height: $line-height-normal;
    text-align: right;
  }
  @include mobile {
    position: absolute;
    top: 16px;
    left: $mobile-spacing;

    padding-left: 6px;

    color: $color-gray;
  }
}

.FormGroup-label-icon {
  @include mobile {
    margin-left: 32px;
  }
}

.FormGroup-input-icon {
  padding-left: 38px;
}

.FormGroup-radioLabel {
  @include mobile {
    position: static;

    padding-bottom: 10px;

    color: initial;
    font-size: 14px;
    font-weight: bold;
  }
}

.FormGroup-inputGroup {
  @include mobile {
    display: inline;

    width: 100%;
  }
  @include desktop {
    width: 400px;
  }
}

.FormGroup-inputGroup--long {
  @include desktop {
    width: $button-width;
  }
}

.FormGroup-inputGroup--small {
  @include desktop {
    width: 185px;
  }
}

.FormGroup-inputGroup--extraSmall {
  @include desktop {
    width: 120px;
  }
}

.FormGroup-radioGroup {
  display: inline-flex;
}

.FormGroup-radioOption {
  display: inline-flex;

  align-items: center;

  margin-right: 20px;
}

.FormGroup-radio,
.FormGroup-radioOption > [class^="InputRadioButton"] {
  margin-right: 10px;
}

.FormGroup-textarea {
  @extend %default-input;
  @include desktop {
    width: $button-width;
  }
  @include mobile {
    width: 100%;
  }

  height: 200px;
  margin-right: 20px;

  line-height: $line-height-normal;
}

.FormGroup-textarea--withError {
  border-color: $color-cinnabar;
}

.FormGroup-input {
  @extend %default-input;

  width: 100%;
}

.FormGroup-input--withError {
  @extend %input--withError;
}

.FormGroup-errorMessage {
  @include mobile {
    text-align: initial;
  }
  @include desktop {
    width: 110%;
  }

  display: flex;

  color: $color-cinnabar;
  line-height: $line-height-normal;
}

.FormGroup-errorMessage--break {
  @include desktop {
    width: 400px;
  }

  white-space: normal;
}

.FormGroup-feedback {
  @include mobile {
    display: none;
  }
  @include desktop {
    width: 35px;
  }
}

.FormGroup-children {
  @include desktop {
    align-self: flex-start;

    margin-top: 6px;
    margin-right: 10px;
    margin-left: 10px;
  }
}

.FormGroup-cvv .FormGroup-children {
  @include desktop {
    display: flex;
    width: 40%;
    height: 40px;
    margin-top: 0;
  }
}

.FormGroup-cvv {
  position: relative;
  display: flex;
  margin-bottom: 20px;

  div:first-child {
    margin-right: 6px;
  }
}

.FormGroup-icon-card {
  position: absolute;
  margin: 0 12px;
  fill: #8b8b8b;
  width: 18px;
  @include desktop {
    top: -7px;
  }
}

.FormGroup-icon-user {
  position: absolute;
  margin: 0 12px;
  @include mobile {
    top: 18px;
  }
  @include desktop {
    top: 12px;
  }
}

@include mobile {
  .FormGroup--withIcon {
    .FormGroup-inputGroup {
      width: calc(100% - 40px);
    }

    .FormGroup-feedback {
      display: inline-flex;
    }

    .ButtonLink {
      position: absolute;
      top: 20px;
      right: 10px;
    }
  }
}

// stylelint-disable no-invalid-position-at-import-rule

@import "style/base/inputs.scss";
