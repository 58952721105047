@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.OrderReview {
  padding-top: 20px;
}

.OrderReview-title {
  font-size: 20px;
}

.OrderReview-container {
  display: flex;

  padding-top: 20px;
}

.OrderReview-store {
  padding-top: 20px;
}

.OrderReview-giftCard {
  margin-bottom: 20px;
}

@include desktop {
  .mobile-only {
    display: none;
  }

  .OrderReview-leftContainer {
    min-width: 62%;
  }

  .OrderReview-titleContainer {
    display: flex;
    flex-direction: column;
  }

  .OrderReview-rightContainer {
    min-width: 35%;

    border-left: 1px solid $color-mercury;
  }
}

@include mobile {
  .desktop-only {
    display: none;
  }

  .OrderReview-titleContainer {
    padding-right: var(--tom-spacing-md);
    padding-left: var(--tom-spacing-sm);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .OrderReview-title {
    color: var(--tom-color-on-surface-1);
    font-size: 18px;
    font-weight: $font-weight-bold;
  }

  .OrderReview-container {
    display: flex;

    flex-direction: column;
  }

  .OrderReview-leftContainer {
    order: 2;
  }

  .OrderReview-leftContainer .OrderReview-title {
    display: flex;

    margin-top: 20px;
    margin-left: 15px;
    margin-bottom: var(--tom-spacing-xsm);
  }

  .OrderReview-rightContainer {
    order: 1;
  }

  .OrderReview-giftCard {
    padding-bottom: 20px;
    padding-left: 15px;

    border-bottom: 15px solid $color-mercury;
  }
}
