@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.BasketItem {
  @include desktop {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  &-border-dashed {
    border-bottom: 1px dashed $color-surface;
  }
}

/*
 * Product
 */

.BasketItem-productContainer {
  display: flex;
}

.BasketItem-policyViolation,
.BasketItem-unavailableWarning {
  margin-bottom: 20px;
  color: $color-warning;
}

.BasketItem-policyViolation-button {
  margin-right: 12px;
  background: transparent;
  border: none;
}

.BasketItem-policyViolation {
  @include mobile {
    padding: 12px;
  }
  display: flex;
  align-items: center;
}

.BasketItem-unavailableWarning-link {
  color: $color-azure-radiance;
}

.BasketItem-customerCompanyWarning {
  float: left;

  width: 100%;
  margin-top: 20px;
  margin-right: 20px;

  color: $color-cinnabar;
}

.BasketItem-availabilityWarning {
  float: left;

  width: 100%;
  margin-right: 20px;
  margin-bottom: 20px;

  color: $color-california;
}

.BasketItem-text-bold {
  font-weight: $font-weight-bold;
}

.BasketItem-availabilityWarning-title {
  margin-bottom: 20px;

  color: $color-lochmara;
  font-size: 22px;
}

.BasketItem-availabilityWarning-description {
  margin-bottom: 20px;
}

.BasketItem-availabilityWarning-link {
  @extend %link-button;

  margin-left: 5px;

  color: $color-california;
}

/*
 * Warranty
 */

.BasketItem-warrantyContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

@include mobile {
  .BasketItem-productContainer {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .BasketItem {
    font-size: 14px;
    padding-bottom: 24px;

    &-border-solid {
      padding: 0 16px 24px 16px;
      border-bottom: 0;
    }

    &-border-dashed {
      border-bottom: 1px dashed $color-surface;
      margin: 0 16px 24px 16px;
    }

    &:first-child {
      padding-top: 0;
    }
  }

  .BasketItem-delete-icon {
    display: block;
  }

  .BasketItem-delete-label {
    display: none;
  }

  .BasketItem-warrantyContainer {
    flex-wrap: wrap;

    margin-top: 20px;
    padding-top: 20px;

    .BasketItem-delete-label {
      display: none;
    }

    .BasketItem-delete-icon {
      display: block;
    }

    .BasketItemProduct-quantity {
      order: 1;
    }

    .BasketItemWarranty {
      order: 2;
    }

    .BasketItemProduct-price {
      width: 100%;

      margin-bottom: -24px;
    }
  }

  .BasketItem-unavailableWarning {
    width: auto;
    margin-right: 16px;
    margin-bottom: 24px;
  }

  .BasketItem-availabilityWarning-title {
    font-size: 18px;
    text-align: center;
  }

  .BasketItem-availabilityWarning-description {
    margin-right: $mobile-spacing;
    margin-bottom: $mobile-spacing;
    margin-left: $mobile-spacing;

    line-height: $line-height-small;
    text-align: center;
  }
}
