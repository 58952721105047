@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.Raffle {
  float: left;

  width: 100%;
  margin-top: 20px;
  margin-right: 20px;

  line-height: $line-height-smaller;
}

.Raffle_content {
  border-radius: 4px;
  background: $color-scarlet-gum;
}

.Raffle_coupom {
  overflow: hidden;

  width: 950px;
  margin: 0 auto;
  padding: 10px 0;

  background: url(https://s.mlcdn.com.br/banner/campanhas/valide1.png) no-repeat;
  background-position-y: -10px;
}

.Raffle_coupom__titles {
  float: left;

  width: 300px;
  margin-left: 4px;

  text-align: center;
}

.Raffle_coupom__main-title {
  font-size: 1em;
}

.Raffle_coupom__sub-title {
  margin-top: 5px;

  color: $color-caribbean-green;
  font-size: 0.8em;
  text-transform: uppercase;
}

.Raffle_coupom__bold {
  font-weight: $font-weight-bold;
}

.Raffle_coupom__actions {
  float: right;
  overflow: hidden;

  width: 610px;
}

.Raffle_coupom__call {
  float: left;
}

.Raffle_coupom__parag {
  margin-top: 15px;

  color: $color-white;
  font-size: 0.8em;
  line-height: $line-height-smaller;
}

.Raffle_coupom__link {
  color: $color-white;
}

.Raffle_coupom__exclam {
  display: inline-block;

  width: 13px;
  margin-right: 2px;
  padding: 2px;

  border: 1px solid $color-caribbean-green;
  border-radius: 50%;
  background: $color-caribbean-green;

  color: $color-scarlet-gum;
  font-weight: $font-weight-bold;
  text-align: center;
}

.Raffle_coupom__call-title {
  color: $color-caribbean-green;
  font-size: 1.5em;
  font-weight: $font-weight-bold;
}

.Raffle_coupom__btns {
  float: right;

  width: 170px;
  margin-right: 8px;

  text-align: right;
}

.Raffle_coupom__btn {
  margin-top: 10px;
  padding: 8px 15px;

  border: 1px solid $color-white;
  border-radius: 5px;
  background: transparent;

  color: $color-white;
  font-weight: $font-weight-bold;
}

.Raffle_coupom__rules {
  color: $color-white;
  font-size: 0.9em;
}

.Raffle_coupom__certificate {
  display: block;
  clear: both;

  margin-top: 15px;

  color: $color-white;
  font-size: 0.7em;
  text-align: right;
}

/* MOBILE */

@media (max-width: 950px) {
  .Raffle_content {
    background: $color-scarlet-gum;

    text-align: center;
  }
  .Raffle_coupom {
    width: 100%;

    background: $color-scarlet-gum;
  }
  .Raffle_coupom__titles,
  .Raffle_coupom__actions {
    display: block;
    float: none;

    width: 100%;
    margin-top: 25px;
  }
  .Raffle_coupom__call,
  .coupom__btns {
    display: block;
    float: none;

    width: 80%;
    margin: 0 auto;
  }
  .Raffle_coupom__btns {
    display: block;
    float: none;

    width: 80%;
    margin: 20px auto;

    text-align: center;
  }
  .Raffle_coupom__rules {
    display: block;
  }
  .Raffle_coupom__certificate {
    text-align: center;
  }
  .Raffle_coupom__link {
    position: relative;

    display: block;

    margin-top: 15px;
  }
}
