@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.SignupFormPJ {
  @include clearfix();
}

.FormGroup-state-registration {
  @include mobile {
    display: inline-block;

    padding-top: 10px;
  }
}

.FormGroup-state-registration .CheckboxGroup {
  @include desktop {
    margin: 0;
  }
}

.SignupFormPJ-divider-line {
  height: 1px;

  border: 0;
  margin-top: -15px;
  margin-bottom: 25px;

  background-color: $color-silver-light;
  @include desktop {
    margin-left: 366px;
  }
}
