@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.ModalContainer {
  position: fixed;
  z-index: $z-index-medium;
  top: 0;
  bottom: 0;
  left: 0;
  float: left;
  overflow-x: hidden;
  overflow-y: auto;

  width: 100%;
  margin-right: 20px;
  padding-top: 0;

  background-color: $color-white;
}

.ModalContainer-CloseIcon {
  @include CloseIcon();
}

.DeliveryModal-header {
  display: block !important;

  margin-top: 23px;
  margin-bottom: 12px;

  color: $color-lochmara;
  font-size: 20px;
  font-weight: $font-weight-bold;
  text-align: center;
}

.AddressModal-header {
  margin-top: 17px;
  margin-bottom: -54px;
}
