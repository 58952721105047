@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.ChangeDataBox * {
  font-family: var(--tom-font-family);
}

.ChangeDataBox {
  background: var(--tom-color-on-brand-default);
  width: 408px;
  min-height: 420px;
  border-radius: var(--tom-radius-lg);
  padding: var(--tom-spacing-2xlg);
  @include mobile {
    padding: var(--tom-spacing-md);
    display: unset;
    width: 100%;
    border-radius: 0;
    height: 100vh;
  }
}

.ChangeDataBox-data {
  font: var(--tom-font-xsm-medium);
}

.ChangeDataBox-title {
  font: var(--tom-font-lg-bold);
  color: var(--tom-color-on-surface-2);
  margin-bottom: var(--tom-spacing-sm);
  letter-spacing: -0.7px;
}

.ChangeDataBox-subtext {
  width: 328px;

  [name="email"] {
    margin-bottom: var(--tom-spacing-sm);
    line-height: 18px;
  }

  &-instructions {
    margin-bottom: var(--tom-spacing-sm);
  }

  [name="telephone"] {
    margin-bottom: var(--tom-spacing-sm);
    display: inline-flex;
    align-items: center;

    .ChangeDataBox-data {
      margin-left: var(--tom-spacing-2xsm);
    }
  }
  @include mobile {
    width: 100%;
  }
}

.ChangeDataBox .FormGroup-input {
  max-width: 328px;
  height: 48px;
  padding-left: var(--tom-spacing-md);
  font: var(--tom-font-sm-regular);
  color: var(--tom-color-on-surface-3);
  border-radius: var(--tom-radius-lg);
  @include mobile {
    max-width: unset;
    width: 100%;
  }
}

.ChangeDataBox .FormGroup-input:focus-visible {
  outline: 1px solid var(--tom-color-brand-default);
  background-color: var(--tom-color-brand-lighter-inverted);
}

.ChangeDataBox-form {
  margin-top: var(--tom-spacing-lg);

  div:first-child {
    margin-bottom: 0.5rem;
  }
}

.ChangeDataBox-form-continue {
  @extend %continue-button;

  width: 100%;
  max-width: 328px;
  height: 48px;
  background-color: var(--tom-color-brand-default);
  font: var(--tom-font-xsm-bold);
  border-radius: var(--tom-radius-lg);
  @include mobile {
    max-width: unset;
  }
}

.ChangeDataBox-form-continue:hover {
  background-color: var(--tom-color-interaction-lighter);
  border-color: var(--tom-color-on-interaction-lighter);
}

.ChangeDataBox .FormGroup-label {
  justify-content: flex-start;
  font: var(--tom-font-xsm-medium);
  color: var(--tom-color-on-surface-4);
  margin-bottom: calc(var(--tom-spacing-2xsm) * -1);
  margin-left: var(--tom-spacing-2xsm);
  display: inline-flex;
  align-items: center;
  height: 40px;
  position: relative;
  top: 0;
  left: 0;
  padding-left: 0;
}

.ChangeDataBox .FormGroup {
  flex-direction: column;
  margin-bottom: var(--tom-spacing-md);
}

.ChangeDataBox .icon {
  display: block;
}

@include mobile {
  .ChangeDataBox-title {
    font: var(--tom-font-md-bold);
  }

  .ChangeDataBox .FormGroup-input {
    padding-top: var(--tom-spacing-none);
  }
}

.ChangeDataBox .FormGroup-inputGroup {
  width: 328px;
  @include mobile {
    width: 100%;
  }
}

.ChangeDataBox .FormGroup-errorMessage {
  color: var(--tom-color-danger-default);
  font: var(--tom-font-xsm-medium);
}

.ChangeDataBox p.FormGroup-errorMessage {
  font: var(--tom-font-xsm-regular);
  white-space: normal;
}

.ChangeDataBox .FormGroup-error {
  color: var(--tom-color-danger-default);
  font: var(--tom-font-xsm-medium);
  display: flex;
  gap: var(--tom-spacing-2xsm);
  padding-left: var(--tom-spacing-2xsm);
  padding-top: var(--tom-spacing-xsm);
  align-items: flex-start;
}
.ChangeDataBox .FormGroup-input--withError {
  border-color: var(--tom-color-danger-default);
}

.ChangeDataBox .ti.ti-highlight-off {
  position: absolute;
  float: right;
  right: 16px;
  font-size: var(--tom-spacing-lg);
  top: 48px;
}
