@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

@keyframes animateHide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;

    bottom: -100vh;
  }
}

@keyframes animateShow {
  from {
    opacity: 0;

    bottom: -100vh;
  }
  to {
    opacity: 1;
  }
}

.BasketContinueFloating {
  @include desktop {
    display: none;
  }

  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $color-white;

  box-shadow: 0 0 13px 2px rgba(0, 0, 0, 0.18824);

  padding: 18px 16px 36px 16px;

  animation-name: animateHide;
  animation-duration: 120ms;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.BasketContinueFloating--show {
  z-index: $z-index-high;

  animation-name: animateShow;
  animation-duration: 120ms;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.BasketContinueFloating-table {
  margin-bottom: 10px;
  font-weight: $font-weight-medium;
  font-size: 14px;
}

.BasketContinueFloating-titleTotal {
  font-size: 16px;
  font-weight: 700;
}

.BasketAddress-floating-free {
  font-size: 14px;
  font-weight: $font-weight-bold;
  color: $color-apple;
}

.BasketContinueFloating-line {
  @include desktop {
    margin-top: 4px;
  }

  display: flex;
  justify-content: flex-end;
}

.BasketContinueFloating-value {
  font-weight: $font-weight-medium;
}

.BasketContinueFloating-title-value {
  display: flex;
  align-items: center;
  gap: 5px;
}

.BasketContinueFloating-value-delivery {
  font-weight: $font-weight-medium;
}

.BasketContinueFloating-value-shipment {
  display: flex;
  flex-direction: column;
  align-items: end;
  font-size: 16px;
  p:first-child {
    font-weight: 700;
  }
}

.BasketContinueFloating-separator {
  border-bottom: 1px solid $color-mercury;
  margin: 10px 0;
}

@include mobile {
  .BasketContinueFloating-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
}
