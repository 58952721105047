@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.ModalDefault {
  &-wrapper {
    display: flex;

    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    z-index: 3;

    .cursor-pointer {
      cursor: pointer;
    }
  }

  &-overlay {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    background-color: rgba(#000, 0.63);
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 32px 32px 24px;
    border-bottom: 1px solid #d3dadd;

    gap: 20px;

    .font-lg-bold {
      font: var(--tom-font-lg-bold);
    }

    .ti-arrow-back {
      display: none;
    }

    .icon-close {
      display: flex;
    }
  }

  &-content {
    background-color: #fff;
    border-radius: 12px;

    position: absolute;
    left: 50%;
    top: 5%;
    transform: translateX(-50%);
    z-index: 2;
  }

  &-body {
    padding: 24px 32px;
  }

  &-footer {
    padding: 24px;
    border-top: 1px solid #d3dadd;
  }
}

@include mobile {
  .ModalDefault-drawer {
    .ModalDefault {
      &-content {
        width: 80%;
        top: 0;
        right: 0;
        left: unset;
        transform: none;
        border-radius: 0;
      }

      &-header {
        padding: 16px;
        justify-content: flex-start;

        .ti-arrow-back {
          display: flex;
        }

        .icon-close {
          display: none;
        }
      }

      &-body {
        height: calc(100vh - 61px - 91px);
        overflow: auto;
      }
    }
  }
}
