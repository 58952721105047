@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.CardLuiza {
  margin-top: 5px;

  color: $color-mine-shaft;
  font-size: 13px;
  font-weight: $font-weight-normal;
}
.CardLuiza__icon-cardluiza {
  margin-right: 24px;
  svg {
    width: 34px;
  }
}

@include desktop {
  .CardLuiza {
    display: inline-flex;
  }
  .CardLuiza__text-luiza {
    width: 235px;
  }
  .BasketPriceBox-prices .CardLuiza {
    display: flex;

    justify-content: flex-end;

    margin-left: 92px;
  }
}

@include mobile {
  .CardLuiza {
    display: flex;
    text-align: right;
    justify-content: flex-end;
    margin: 0 16px 16px 16px;
  }

  .OrderReviewTotals .CardLuiza {
    float: right;

    margin-top: 5px;
    margin-right: 15px;
    margin-left: 0;

    text-align: right;
  }

  .CardLuiza__icon-cardluiza {
    display: none;
  }
}
