@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.InternationalTax-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 14px;

  .InternationalTax-row {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
  }

  .InternationalTax-text,
  .InternationalTax-tax-text {
    font: var(--tom-font-2xsm-regular);
  }
  @include mobile {
    .InternationalTax-tax-text {
      flex-basis: min-content;
    }
  }
}
