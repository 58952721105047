@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

@import "../../../../style/resources.scss";

.StorePickupToken {
  font-family: var(--tom-font-family);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 8px 12px;
  background-color: $color-california-light;
  border: 1px solid $color-california-border;
  width: 100%;
}

.StorePickupToken .ti {
  float: left;
  margin-right: 10px;
  font-size: 22px;
  color: #ccac00;
}

.StorePickupToken-warning-text {
  width: 100%;
  display: table-row;
}

@include mobile {
  .StorePickupToken {
    width: 100%;
  }
}
