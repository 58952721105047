/* stylelint-disable */
@mixin rounded($top: $border-radius-size, $right: null, $bottom: null, $left: null) {
  @if ($right or $bottom or $left) {
    border-top-left-radius: $left;
    border-top-right-radius: $right;
    border-bottom-right-radius: $bottom;
    border-bottom-left-radius: $left;
  } @else {
    border-radius: $top;
  }
}

@mixin rounded-with-border($radius: $border-radius-size) {
  @include rounded($radius);

  border: 1px solid $color-mercury;
}

@mixin clearfix() {
  &:after {
    display: table;
    clear: both;

    content: "";
  }
}

@function percentage($value, $base: $desktop-width) {
  @return $value / $base * 100%
}
