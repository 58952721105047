@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.AddressBox {
  @apply flex px-lg py-md rounded-lg font-2xsm-regular border-on-surface-7 radio w-full ;
  @apply mb-xsm box-border has-[:checked]:border-interaction-default transition-colors;

  border-width: var(--tom-border-thin);

  &.AddressFormContainer {
    display: block;
  }

  input {
    @apply mr-lg;

    width: 20px;
    height: 20px;
  }
}

.AddressBox-title {
  @apply font-2xsm-bold;
}

.AddressBox-label {
  @apply flex flex-col w-full;
}

@include mobile {
  .ModalContainer {
    .AddressBox {
      margin: 0;
      padding: 60px $mobile-spacing $mobile-spacing $mobile-spacing;
    }
  }
}
