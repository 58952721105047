@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.DeliveryAddress {
  @apply max-w-screen-md mx-auto;
}

.DeliveryAddress-title {
  @apply text-on-surface-3 font-xsm-bold border-on-surface-7 py-md mb-md max-md:px-sm;
  @apply md:pb-lg md:pt-md md:font-md-bold;

  border-bottom-width: var(--tom-border-thin);
}

@include desktop {
  .AddressForm-form-group {
    margin-bottom: 20px;
  }
}

@include mobile {
  .DeliveryAddress-title {
    margin-top: $mobile-spacing;
  }
}
