@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

@import "style/resources.scss";

.SocialLoginBox {
  padding: 5px 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mobile {
    flex-direction: column;
  }
}

.SocialLoginBox-group-buttons {
  display: flex;
  align-items: center;
}

.SocialLoginBox-button {
  width: 20px;
  height: 20px;
  margin-left: 10px;

  cursor: pointer;
  vertical-align: middle;
}

.SocialLoginBox-button--FB {
  @extend .SocialLoginBox-button;
}

.SocialLoginBox-button--Google {
  @extend .SocialLoginBox-button;
}

%socialLoginButton {
  width: 136px;
  margin: 0 9px;
  padding: 5px 6px 5px 6px;

  border: 0;
  border-radius: 3px;

  text-align: left;
}

.SocialLogin-Facebook {
  @extend %socialLoginButton;

  margin: 4px;

  background-color: $color-san-marino;
}

.SocialLogin-Google {
  @extend %socialLoginButton;

  margin: 4px;

  background-color: $color-concrete;
}

%socialLoginButtonText {
  margin-left: 10px;

  font-size: 15px;
}

.SocialLoginBox-button--ButtonNameFB {
  @extend %socialLoginButtonText;

  color: $color-white;
}

.SocialLoginBox-button--ButtonNameGoogle {
  @extend %socialLoginButtonText;

  color: $color-black;
}

.SocialLoginBox--InformationLogin {
  padding: 6px;
}

@include mobile {
  .SocialLoginBox--InformationLogin {
    display: block;
  }

  .SocialLoginBox--Divider {
    display: inline-block;

    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .SocialLoginBox {
    margin-top: -15px;
    padding: 0;
  }
}
