@import "../../../../../style/variables/colors.scss";
@import "../../../../../style/variables/typography.scss";
@import "../../../../../style/variables/forms.scss";
@import "../../../../../style/variables/general.scss";
@import "../../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../../style/utils/helpers";
@import "../../../../../style/utils/responsive";
@import "../../../../../style/utils/typography";
@import "../../../../../style/utils/list";
@import "../../../../../style/utils/buttons";
@import "../../../../../style/utils/inputs";
@import "../../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../../style/components/placeholders/GenericBox";

@import "components/Basket/Basket.scss";

.BasketItemWarranty {
  width: $basket-product-width;
}

.BasketItemWarranty-title {
  margin-left: 142px;
}

@include mobile {
  .BasketItemWarranty-title {
    width: 163px;
    margin-right: 0;
    margin-bottom: 20px;
    margin-left: $mobile-spacing;
  }
  .BasketItemWarranty-title::before {
    display: block;

    width: 100%;
    margin-bottom: $mobile-spacing;

    font-weight: bold;
    content: "Garantia";
  }
  .BasketItemWarranty {
    width: auto;
  }
}
