@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

@import "../../../../style/resources.scss";

.ConfirmOrderBox {
  display: flex;

  flex-wrap: wrap;

  padding: 20px;

  background-color: $color-wild-sand;
}

.ConfirmOrderBox-barCode {
  margin-left: -10px; // The svg has a transparent padding. We need to correct.
}

.ConfirmOrderBox-orderNumber {
  margin-bottom: 5px;

  font-size: 24px;
  font-weight: $font-weight-bold;
}

.ConfirmOrderBox-rightContainer {
  width: 50%;
}

.ConfirmOrderBox-colorBlue {
  color: $color-lochmara;
}

.ConfirmOrderBox-buttonsHolder {
  line-height: $line-height-biggest;
}

.ConfirmOrderBox-trackOrder {
  @extend %secondary-button;

  float: left;

  width: 224px;
  margin-right: 20px;
}

.ConfirmOrderBox-trackOrder:hover {
  @extend .ConfirmOrderBox-trackOrder;

  background-color: $color-bahama-blue;
}

.ConfirmOrderBox-trackOrder:active {
  @extend .ConfirmOrderBox-trackOrder;

  background-color: $color-bahama-dark;
}

.ConfirmOrderBox-print {
  font-size: 14px;
}

.ConfirmOrderBox-printSlip {
  display: flex;

  flex-wrap: wrap;

  margin-top: 20px;
}

.ConfirmOrderBox-printSlip-button {
  @extend %flex-continue-button;

  margin-bottom: 10px;
}

.ConfirmOrderBox-printSlip-button:hover {
  @extend .ConfirmOrderBox-printSlip-button;

  background-color: $color-la-palma;
}

.ConfirmOrderBox-printSlip-button:active {
  @extend .ConfirmOrderBox-printSlip-button;

  background-color: $color-la-palma-dark;
}

.ConfirmOrderBox-printSlip-copy {
  float: left;

  width: 100%;
  margin-right: 20px;

  font-size: 14px;
}

.ConfirmOrderBox-printSlip-slipInstruction {
  width: 100%;
  margin-bottom: 10px;

  font-size: 14px;
}

.ConfirmOrderBox-bankSlip {
  width: 90%;

  border: none;
  background-color: transparent;

  resize: none;
}

.ConfirmOrderBox-bankSlip-title {
  font-weight: $font-weight-bold;
}

.ConfirmOrderBox-payment {
  display: flex;
}

.ConfirmOrderBox-payment_types {
  margin-left: 5px;
}

.ConfirmOrderBox-payment_types-amount {
  color: $color-gray;
  font-size: 14px;
}

.ConfirmOrderBox-payment_types-expiration {
  color: $color-california;
}

.CardLuizaInformation {
  float: left;

  width: 100%;
  margin-top: 8px;

  text-align: center;
}

.cardluiza-confirmorder__success {
  color: $color-apple;
  font-size: 16px;
  font-weight: $font-weight-bold;
}

.cardluiza-confirmorder__information {
  font-size: 16px;
}

.cardluiza-confirmorder__terms {
  color: $color-gray;
  font-size: 13px;
}

@include desktop {
  .ConfirmOrderBox {
    @include rounded();

    margin-right: -20px;
    margin-left: -20px;
  }

  .ConfirmOrderBox-leftContainer {
    width: 50%;
  }

  .ConfirmOrderBox-copyIcon {
    float: right;

    width: 20px;
    height: 20px;
    margin-top: -60px;
    margin-right: 20px;

    vertical-align: middle;

    fill: $color-forest-green;
  }
}

@include mobile {
  .ConfirmOrderBox-leftContainer {
    margin-bottom: $mobile-spacing;

    text-align: center;
  }

  .ConfirmOrderBox {
    margin-bottom: 20px;
  }

  .ConfirmOrderBox-show-details {
    text-align: center;
  }

  .ConfirmOrderBox-rightContainer {
    float: left;

    width: 90%;
    margin-right: 20px;
  }

  .ConfirmOrderHeader-description,
  .ConfirmOrderHeader-description--warning {
    padding: 10px;

    background-color: $color-wild-sand;

    text-align: center;
  }

  .ConfirmOrderBox-copyIcon {
    float: right;
  }

  .ConfirmOrderBox-orderNumber {
    color: $color-lochmara;
  }

  .ConfirmOrderBox-copyText {
    display: block;

    margin-bottom: $mobile-spacing;

    color: $color-apple;
    font-size: 14px;
  }

  .CardLuizaInformation {
    width: 291px;
    height: 137px;
    margin-top: 20px;
    margin-bottom: -50px;
    padding-left: $mobile-spacing;
  }
}

.ConfirmOrderBox-samedayMessage {
  color: $color-california;
}

.ConfirmOrderBox-StorePickupToken {
  display: contents;
}

@include desktop {
  .ConfirmOrderBox-StorePickupToken .StorePickupToken {
    margin-top: 20px;
  }
}
