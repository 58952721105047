@import "../../../../../style/variables/colors.scss";
@import "../../../../../style/variables/typography.scss";
@import "../../../../../style/variables/forms.scss";
@import "../../../../../style/variables/general.scss";
@import "../../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../../style/utils/helpers";
@import "../../../../../style/utils/responsive";
@import "../../../../../style/utils/typography";
@import "../../../../../style/utils/list";
@import "../../../../../style/utils/buttons";
@import "../../../../../style/utils/inputs";
@import "../../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../../style/components/placeholders/GenericBox";

@import 'components/Basket/Basket.scss';

.BasketItemProduct {
  display: flex;
}

.BasketItemProduct-image {
  min-width: 102px;
  @include desktop {
    margin-right: 40px;
  }
}

.BasketItemProduct-info-title {
  color: $color-dark-gray;
  text-decoration: none;
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-marker-info;
}

.BasketItemProduct-info-title--unavailable {
  @extend .BasketItemProduct-info-title;

  color: $color-silver;
}

.BasketItemProduct-info-sku {
  @extend %text-info;
  font-weight: $font-weight-normal;
}

.BasketItemProduct-info-sku--unavailable {
  @extend .BasketItemProduct-info-sku;

  color: $color-silver;
}

.BasketItemProduct-info-extra {
  float: right;

  width: 100%;
  margin-right: 0;

  color: $color-mine-shaft;
  text-decoration: none;
}

.BasketItemProduct-tag-international {
  @include desktop {
    margin-top: 16px;
  }
  @include mobile {
    margin-top: 12px;
  }
}

.BasketItemProduct-info-store {
  @include desktop {
    margin-top: 16px;
  }
  @include mobile {
    margin-top: 12px;
  }

  color: $color-gray;
}

.BasketItemProduct-info-store-link {
  color: $color-mine-shaft;
  font-weight: 700;
}

.BasketItemProduct-informative {
  font-size: 14px;
}

.BasketItemProduct-delivery-iconConventional {
  margin-right: 6px;
  width: 30px;
  height: 30px;
  fill: #535353;

  &-hours,
  &-international {
    margin-right: 6px;
    width: 18px;
    height: 18px;
    fill: #535353;
  }
}

.BasketItemProduct-delivery-one-hour,
.BasketItemProduct-delivery-message {
  color: $color-apple;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.BasketItemProduct-magazine {
  position: relative;
  top: -2px;
  left: 4px;

  color: $color-apple;
  font-size: 14px;
  font-weight: 400;
}

.BasketItemProduct-quantity {
  margin-left: 80px;
  margin-right: 80px;

  text-align: center;
}

.BasketItemProduct-quantity-dropdown {
  @extend %default-input;

  width: 58px;
  height: 40px;
}

.BasketItemProduct-quantity-dropdown--unavailable {
  @extend .BasketItemProduct-quantity-dropdown;
  @extend %default-input-disabled;
}

.BasketItemProduct-quantity-dropdown:disabled {
  @extend %default-input-disabled;
}

.BasketItemProduct-quantity-remove {
  @extend %link-button;

  display: block;

  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;

  color: $color-gray;
  font-size: 14px;
  line-height: 1;
}

.BasketItem-delete-icon {
  display: none;
}

.BasketItemProduct-price {
  width: $basket-price-width;
  font-weight: $font-weight-normal;
  color: $color-dark-gray;
  font-size: 18px;
  text-align: right;
}

.BasketItemProduct-to {
  font-weight: $font-weight-bold;
}

.BasketItemProduct-price--unavailable {
  @extend .BasketItemProduct-price;

  color: $color-silver;
}

@include desktop {
  .BasketItemProduct {
    width: $basket-product-width;
  }
}

@include mobile {
  .BasketItemProduct-price {
    width: 45%;
    margin-top: $mobile-spacing;
    font-size: $font-size-link;
  }

  .BasketItemProduct {
    width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
  }

  .BasketItemProduct-image {
    margin-right: 20px;
  }

  .BasketItemProduct-quantity-title {
    display: block;

    font-size: 14px;
  }

  .BasketItemProduct-quantity {
    float: left;

    margin-right: $mobile-spacing;
    margin-left: 0;

    text-align: left;
  }

  .BasketItemProduct-quantity-dropdown {
    float: left;

    width: 4rem;
    padding-bottom: 10px;
  }

  .BasketItemProduct-quantity-remove {
    @include rounded-with-border($border-radius-size);

    float: left;

    margin-top: 0;
    margin-left: $mobile-spacing;
    padding: $mobile-spacing;
  }

  .BasketItemProduct-info-title {
    font-size: $font-size-link;
  }

  .BasketItemProduct-info-sku {
    font-size: 12.5px;

    margin-top: 4px;
  }
}
