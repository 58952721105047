@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.ZipcodeForm {
  display: inline;
}

.ZipcodeForm-input {
  @extend %input-within;
  @extend %input-numeric;
}

.ZipcodeForm-link {
  margin-left: 56px;
}

@include mobile {
  .ZipcodeForm {
    display: inline-flex;
    margin-top: 4px;
    width: 100%;
  }

  .ZipcodeForm-input {
    height: 44px;
    font-size: 16px;
    width: 70%;

    &::placeholder {
      color: $color-gray;
    }
  }

  .ZipcodeForm-link {
    margin-left: 4px;
    margin-top: 12px;
    line-height: 19.69px;
    width: 100%;
  }

  .ZipcodeForm .buttonWithin {
    width: 50px;
    font-size: 16px;
    height: 44px;
    font-weight: $font-weight-medium;
    &-text {
      position: relative;
      top: -3px;
    }
  }
}
