@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

.BasketPriceBox {
  display: flex;

  padding-top: 5px;
  padding-bottom: 24px;
  justify-content: space-between;
  &-unavaible {
    display: flex;
    width: 100%;
    justify-content: right;
  }
}

@include desktop {
  .BasketPriceBox {
    flex-direction: column;
    padding: 24px 12px 24px 12px;

    border-radius: 3px;
    background-color: $color-on-surface;

    position: relative;
    z-index: 2;
  }
}

@include mobile {
  .BasketPriceBox {
    flex-direction: column;
    flex-wrap: wrap;

    z-index: 2;

    &-shipment {
      width: 100%;
    }
    &-prices--values,
    &-price {
      width: 100%;
    }
  }
}
