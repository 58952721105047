@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.SelectEmailOrSms-wrapper,
.SelectEmailOrSms-wrapper-modal {
  margin-top: 32px;
  padding: 40px;
  text-align: center;
  background-color: #fff;
  border-radius: 15px;
  width: 408px;
  height: 405px;
  @include mobile {
    background-color: var(--tom-color-on-surface-9);
    padding: var(--tom-spacing-md);
    padding-top: var(--tom-spacing-2xlg);
    border-radius: 0;
    margin-top: 0;
    width: 100vw;
    height: 100vh;
  }
}

.SelectEmailOrSms-wrapper-modal {
  @include desktop {
    margin: auto;
    width: auto;
    height: auto;
    padding: 0;
  }
  @include mobile {
    width: auto;
    height: 100vh;
  }
}

.SelectEmailOrSms-title {
  font-weight: $font-weight-medium;
  font-size: 2rem;
  margin: 8px 0;
  text-align: center;
}

.SelectEmailOrSms-text-info {
  display: inline-block;
  word-wrap: break-word;
  max-width: 262px;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}

.SelectEmailOrSms-outlined-btn {
  margin-top: 22px;
  font-size: 1.2rem;
  border: none;
  background: transparent;
  color: $color-azure-radiance;

  &:hover {
    text-decoration: underline;
  }
}

.SelectEmailOrSms-wrapper-error {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  max-width: 100%;
  color: $color-cinnabar;

  span {
    margin-left: 5px;
  }
  @include mobile {
    padding: 0 12px;
  }
}
