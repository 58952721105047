@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

$arrow-product-margin: -24px;

%BobbyArrow {
  $height: 30px;

  position: absolute;
  top: calc(50% - (#{$height} / 2));

  width: 20px;
  height: $height;

  cursor: pointer;

  fill: $color-lochmara;
}

.BobbyArrow-left {
  @extend %BobbyArrow;

  left: $arrow-product-margin;
}

.BobbyArrow-right {
  @extend %BobbyArrow;

  right: $arrow-product-margin;
}

.BobbyArrow-left--disabled {
  @extend .BobbyArrow-left;

  fill: $color-gray;
}

.BobbyArrow-right--disabled {
  @extend .BobbyArrow-right;

  fill: $color-gray;
}

@include mobile {
  .BobbyArrow-left,
  .BobbyArrow-left--disabled,
  .BobbyArrow-right,
  .BobbyArrow-right--disabled {
    visibility: hidden;
  }
}
