/*
 * Colors
 */
//Gray
$color-mine-shaft: #404040;
$color-very-dark-gray: #515151;
$color-dark-gray: #5d5d5d;
$color-gray: #8c8c8c;
$color-gray-neutral: #424a52;
$color-silver: #b9b9b9;
$color-silver-light: #cbcbcb;
$color-silver-chalice: #b3b3b3;
$color-mercury: #e5e5e5;
$color-wild-sand: #f4f4f4;
$color-concrete: #f2f2f2;
$color-spindle: #cce6f4;
$color-white: #fff;
$color-surface: #d3dadd;
$color-on-surface: #f0f3f4;
$color-gray-light: #e3e8ea;
//Blue
$color-seagull: #77c1e4;
$color-lochmara: #0083ca;
$color-bahama-blue: #016fab;
$color-bahama-dark: #024f79;
$color-san-marino: #4267b2;
$color-azure-radiance: #0086ff;
$color-science-blue: #0064bf;
$color-bahama-blue: #006195;
//Green
$color-apple: #58c22e;
$color-christi: #59c00b;
$color-la-palma: #48a524;
$color-la-palma-dark: #378e16;
$color-green-free: #59c10c;
//Pink
$color-cerise: #db44a7;
$color-rouge: #9f3d95;

/*
 * Colors - Feedbacks
 */
$color-california: #fb9600; // warning
$color-california-border: #eac600;
$color-california-light: #fffceb; // light warning
$color-cinnabar: #e25335; // error
$color-forest-green: #3fcb2a; // success
$color-warning: #ed6a0a;

/*
 * Colors - Campanha Outubro Rosa
 */
$color-hot-pink: #ff50b3;
$color-hibiscus: #a5296f;

/*
 * Colors - Black Friday
 */
$color-black: #000;
$color-dove-gray: #666;

/*
 * Colors - Liquidação Fantástica
 */
$color-fantastic-sale: #bd021d;
$color-fantastic-sale-text-footer: #aad0e6;

/*
 * Colors - PayDay
 */
$color-payday: #040949;

/*
 * Colors - RGB
 */
$color-scarlet-gum: rgb(62, 24, 112);
$color-caribbean-green: rgb(0, 233, 167);
