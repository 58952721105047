@import "../../../style/variables/colors.scss";
@import "../../../style/variables/typography.scss";
@import "../../../style/variables/forms.scss";
@import "../../../style/variables/general.scss";
@import "../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../style/utils/helpers";
@import "../../../style/utils/responsive";
@import "../../../style/utils/typography";
@import "../../../style/utils/list";
@import "../../../style/utils/buttons";
@import "../../../style/utils/inputs";
@import "../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../style/components/placeholders/GenericBox";

@import "style/resources.scss";

.Timer {
  margin-top: 24px;

  text-align: center;
}

.Timer-formattedTime {
  color: $color-gray;
  font-weight: 600;
  margin-left: -16px;
}

.Timer-button {
  border: none;
  background-color: transparent;

  color: $color-azure-radiance;

  &:disabled {
    color: $color-gray;
  }
}
