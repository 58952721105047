@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.OrderReviewItem-zipCodeRestriction {
  color: $color-california;
}

.OrderReviewVariation {
  @include rounded();

  display: inline-block;
  margin-right: 15px;
  padding: 1px 7px;
  position: relative;
  top: 5px;

  background-color: $color-gray-light;

  font-size: 13px;
  font-weight: bolder;
  letter-spacing: 1px;
  margin-bottom: 4px;
}

.OrderReviewItem {
  margin-bottom: 4px;
}

.OrderReviewItem:last-of-type {
  margin-bottom: unset;
}
