@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.OtpErrorNotification-wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--tom-color-on-brand-default);
  @include desktop {
    padding: 0 15px;
    left: calc(50vw - 185px);
    margin-left: auto;
    margin-right: auto;
    top: 27vh;
    border-radius: var(--tom-radius-lg);
    max-width: 360px;
  }
  @include mobile {
    width: 100%;
    padding: 0 16px;
    padding-top: 15px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    border-top-left-radius: var(--tom-radius-2xlg);
    border-top-right-radius: var(--tom-radius-2xlg);
  }
}

.OtpOverlay {
  background-color: var(--tom-color-on-surface-2);
  opacity: var(--tom-opacity-20);
  position: fixed;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
}

.OtpErrorNotification-description {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  max-width: 325px;
  @include mobile {
    max-width: max-content;
    gap: 32px;
    margin-bottom: 16px;
  }
  @include desktop {
    margin-bottom: 32px;
    margin-top: 52px;
    gap: 16px;
  }
}

.OtpErrorNotification-title {
  font: var(--tom-font-lg-bold);
  color: var(--tom-color-on-surface-3);
  @include mobile {
    font: var(--tom-font-md-bold);
    display: grid;
    grid-template-columns: 24px 1fr;
    align-self: baseline;
    gap: 13px;
  }
}

.OtpErrorNotification-title .warning-icon-amber {
  fill: #ccac00;
}

.OtpErrorNotification-description p {
  font: var(--tom-font-xsm-regular);
  @include mobile {
    line-height: 25px;
    text-align: left;
    max-width: 328px;
  }
  @include desktop {
    line-height: 18px;
  }
}

.OtpErrorNotification-btn {
  color: var(--tom-color-on-interaction-default);
  background-color: var(--tom-color-interaction-default);
  font: var(--tom-font-xsm-bold);
  width: 100%;
  height: 48px;
  border: none;
  @include desktop {
    border-radius: var(--tom-radius-lg);
    max-width: 305px;
    margin-bottom: 24px;
  }
  @include mobile {
    max-width: 328px;
    border-radius: 8px;
    margin-bottom: 16px;
  }
}

.OtpClose .ti.ti-close {
  font-size: 24px;
  color: var(--tom-color-on-surface-3);
  cursor: pointer;
}
.OtpClose {
  cursor: pointer;
  position: absolute;
  display: flex;
  right: 16px;
  top: 12px;
  max-width: fit-content;
  padding: 4px 4px;
  border-radius: var(--tom-radius-2xlg);
  background-color: var(--tom-color-surface-container-low);
}
