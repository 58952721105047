@import "../../../../style/variables/colors.scss";
@import "../../../../style/variables/typography.scss";
@import "../../../../style/variables/forms.scss";
@import "../../../../style/variables/general.scss";
@import "../../../../style/variables/layout.scss";

/*
 * Utils
 */

@import "../../../../style/utils/helpers";
@import "../../../../style/utils/responsive";
@import "../../../../style/utils/typography";
@import "../../../../style/utils/list";
@import "../../../../style/utils/buttons";
@import "../../../../style/utils/inputs";
@import "../../../../style/utils/icons";

/*
 * Components placeholders
 */

@import "../../../../style/components/placeholders/GenericBox";

.OtpCode-wrapper,
.OtpCode-wrapper-modal {
  font-family: var(--tom-font-family);
  @include mobile {
    text-align: center;
    padding: 16px;
  }
}

.OtpCode-wrapper {
  @include mobile {
    padding: 0;
    padding-bottom: 120px;
  }
}

.OtpCode-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.OtpCode-info {
  width: 328px;
  text-align: justify;
  gap: 24px;
  @include mobile {
    width: 100%;
  }
}

.OtpCode-title {
  white-space: nowrap;
  font-weight: 600;
  font-size: 22px;
  color: var(--tom-color-on-surface-2);
}

.OtpCode-text-info {
  display: inline-block;
  word-wrap: break-word;
  max-width: 479px;
  margin-bottom: 24px;
  text-align: initial;

  &__link {
    margin-top: 6px;
    a {
      cursor: pointer;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: var(--tom-color-brand-default);
    }
  }
}

.OtpCode-info-user {
  font-weight: $font-weight-bold;
}

.OtpCode-outlined-btn {
  margin-top: 48px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  background: transparent;
  color: $color-azure-radiance;

  &:hover {
    text-decoration: underline;
  }
}
